const garmentAllowance = {
  LS: {
    sections: [
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Armhole', entity: 'armhole', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Bicep', entity: 'bicep', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Elbow', entity: 'elbow', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Left Wrist (Single, incl button allowance)', entity: 'leftWrist', defaultPrefValue: 'leftWristSingle', bodySection: 'upperBody', hideWhenDesign: {cuffs: ['frenchBevelD', 'frenchSquareE', 'frenchRoundF']}},
      {label: 'Right Wrist (Single, incl button allowance)', entity: 'rightWrist', defaultPrefValue: 'rightWristSingle', bodySection: 'upperBody', hideWhenDesign: {cuffs: ['frenchBevelD', 'frenchSquareE', 'frenchRoundF']}},
      {label: 'Left Wrist (double)', entity: 'leftWrist', defaultPrefValue: 'leftWristDouble', bodySection: 'upperBody', hideWhenDesign: {cuffs: ['bevelB', 'squareA', 'roundC']}},
      {label: 'Right Wrist (double)', entity: 'rightWrist', defaultPrefValue: 'rightWristDouble', bodySection: 'upperBody', hideWhenDesign: {cuffs: ['bevelB', 'squareA', 'roundC']}},
    ]
  },
  'PRIMO LS': {
    sections: [
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Armhole', entity: 'armhole', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Bicep', entity: 'bicep', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Elbow', entity: 'elbow', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Left Wrist (Single, incl button allowance)', entity: 'leftWrist', bodySection: 'upperBody', hideWhenDesign: {cuffs: ['frenchBevelD', 'frenchSquareE', 'frenchRoundF']}},
      {label: 'Right Wrist (Single, incl button allowance)', entity: 'rightWrist', bodySection: 'upperBody', hideWhenDesign: {cuffs: ['frenchBevelD', 'frenchSquareE', 'frenchRoundF']}},
      {label: 'Left Wrist (double)', entity: 'leftWrist', bodySection: 'upperBody', hideWhenDesign: {cuffs: ['bevelB', 'squareA', 'roundC']}},
      {label: 'Right Wrist (double)', entity: 'rightWrist', bodySection: 'upperBody', hideWhenDesign: {cuffs: ['bevelB', 'squareA', 'roundC']}},
      {label: 'Cuff', entity: 'cuff', bodySection: 'upperBody', hideWhenDesign: {cuffs: ['bevelB', 'squareA', 'roundC']}},      
    ]
  },
  SS: {
    sections: [
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Armhole', entity: 'armhole', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Bicep', entity: 'bicep', bodySection: 'upperBody', hideWhenDesign: {}},
    ]
  }, 
  'PRIMO SS': {
    sections: [
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Armhole', entity: 'armhole', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Bicep', entity: 'bicep', bodySection: 'upperBody', hideWhenDesign: {}},
    ]
  }, 
  LP:  {
    sections: [
      {label: 'Waist (normal)', entity: 'waist', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['high']}},
      {label: 'Waist (high)', entity: 'waistHigh', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['normal']}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Thigh', entity: 'thigh', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Knee', entity: 'knee', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Calf', entity: 'calf', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'LP Cuff', entity: 'cuff', bodySection: 'lowerBody', hideWhenDesign: {}},
      // {label: 'LP Length', entity: 'lpLength', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Left LP Length', entity: 'leftLpLength', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Right LP Length', entity: 'rightLpLength', bodySection: 'lowerBody', hideWhenDesign: {}},
    ],
  },
  'PRIMO LP':  {
    sections: [
      {label: 'Waist (normal)', entity: 'waist', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['high']}},
      {label: 'Waist (high)', entity: 'waistHigh', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['normal']}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Thigh', entity: 'thigh', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Knee', entity: 'knee', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Calf', entity: 'calf', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'LP Cuff', entity: 'cuff', bodySection: 'lowerBody', hideWhenDesign: {}},
      // {label: 'LP Length', entity: 'lpLength', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Left LP Length', entity: 'leftLpLength', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Right LP Length', entity: 'rightLpLength', bodySection: 'lowerBody', hideWhenDesign: {}},
    ],
  },
  SP: {
    sections: [
      {label: 'Waist (normal)', entity: 'waist', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['high']}},
      {label: 'Waist (high)', entity: 'waistHigh', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['normal']}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Thigh', entity: 'thigh', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Knee (Reference from thigh)', entity: 'knee', bodySection: 'lowerBody', hideWhenDesign: {}},
    ],
  },
  'PRIMO SP': {
    sections: [
      {label: 'Waist (normal)', entity: 'waist', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['high']}},
      {label: 'Waist (high)', entity: 'waistHigh', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['normal']}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Thigh', entity: 'thigh', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Knee (Reference from thigh)', entity: 'knee', bodySection: 'lowerBody', hideWhenDesign: {}},
    ],
  },
  SBJ: {
    sections: [
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Armhole', entity: 'armhole', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Bicep', entity: 'bicep', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Elbow', entity: 'elbow', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Left Wrist (Single, incl button allowance)', entity: 'leftWrist', defaultPrefValue: 'leftWristSingle', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['doubleCuff']}},
      {label: 'Right Wrist (Single, incl button allowance)', entity: 'rightWrist', defaultPrefValue: 'rightWristSingle', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['doubleCuff']}},
      {label: 'Left Wrist (double)', entity: 'leftWrist', defaultPrefValue: 'leftWristDouble', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['singleCuff']}},
      {label: 'Right Wrist (double)', entity: 'rightWrist', defaultPrefValue: 'rightWristDouble', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['singleCuff']}},
      {label: 'Lapel', entity: 'lapel', bodySection: 'upperBody', hideWhenDesign: {lapelCollarStyle: ['mandarin']}},
    ]
  },
  'PRIMO SBJ': {
    sections: [
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Armhole', entity: 'armhole', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Bicep', entity: 'bicep', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Elbow', entity: 'elbow', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Left Wrist (Single, incl button allowance)', entity: 'leftWrist', defaultPrefValue: 'leftWristSingle', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['doubleCuff']}},
      {label: 'Right Wrist (Single, incl button allowance)', entity: 'rightWrist', defaultPrefValue: 'rightWristSingle', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['doubleCuff']}},
      {label: 'Left Wrist (double)', entity: 'leftWrist', defaultPrefValue: 'leftWristDouble', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['singleCuff']}},
      {label: 'Right Wrist (double)', entity: 'rightWrist', defaultPrefValue: 'rightWristDouble', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['singleCuff']}},
      {label: 'Lapel', entity: 'lapel', bodySection: 'upperBody', hideWhenDesign: {lapelCollarStyle: ['mandarin']}},
    ]
  },
  DBJ: {
    sections:[
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Armhole', entity: 'armhole', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Bicep', entity: 'bicep', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Elbow', entity: 'elbow', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Left Wrist (Single, incl button allowance)', entity: 'leftWrist', defaultPrefValue: 'leftWristSingle', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['doubleCuff']}},
      {label: 'Right Wrist (Single, incl button allowance)', entity: 'rightWrist', defaultPrefValue: 'rightWristSingle', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['doubleCuff']}},
      {label: 'Left Wrist (double)', entity: 'leftWrist', defaultPrefValue: 'leftWristDouble', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['singleCuff']}},
      {label: 'Right Wrist (double)', entity: 'rightWrist', defaultPrefValue: 'rightWristDouble', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['singleCuff']}},
      {label: 'Lapel', entity: 'lapel', bodySection: 'upperBody', hideWhenDesign: {lapelCollarStyle: ['mandarin']}},
    ]
  },
  'PRIMO DBJ': {
    sections:[
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Hip', entity: 'hip', bodySection: 'lowerBody', hideWhenDesign: {}},
      {label: 'Armhole', entity: 'armhole', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Bicep', entity: 'bicep', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Elbow', entity: 'elbow', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Left Wrist (Single, incl button allowance)', entity: 'leftWrist', defaultPrefValue: 'leftWristSingle', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['doubleCuff']}},
      {label: 'Right Wrist (Single, incl button allowance)', entity: 'rightWrist', defaultPrefValue: 'rightWristSingle', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['doubleCuff']}},
      {label: 'Left Wrist (double)', entity: 'leftWrist', defaultPrefValue: 'leftWristDouble', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['singleCuff']}},
      {label: 'Right Wrist (double)', entity: 'rightWrist', defaultPrefValue: 'rightWristDouble', bodySection: 'upperBody', hideWhenDesign: {cuffSizeType: ['singleCuff']}},
      {label: 'Lapel', entity: 'lapel', bodySection: 'upperBody', hideWhenDesign: {lapelCollarStyle: ['mandarin']}},
    ]
  },
  SBV: {
    sections: [
      {label: 'Vest Shoulder', entity: 'backShoulder', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Vest Bottom (Normal Waist)', entity: 'waist', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['high']}},
      {label: 'Vest Bottom (High Waist)', entity: 'waistHigh', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['normal']}},
    ],
  },
  'PRIMO SBV': {
    sections:[
      {label: 'Vest Shoulder', entity: 'backShoulder', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Vest Bottom (Normal Waist)', entity: 'waist', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['high']}},
      {label: 'Vest Bottom (High Waist)', entity: 'waistHigh', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['normal']}},
    ],
  },
  DBV: {
    sections:[
      {label: 'Vest Shoulder', entity: 'backShoulder', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Vest Bottom (Normal Waist)', entity: 'waist', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['high']}},
      {label: 'Vest Bottom (High Waist)', entity: 'waistHigh', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['normal']}},
    ],
  },
  'PRIMO DBV': {
    sections:[
      {label: 'Vest Shoulder', entity: 'backShoulder', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Chest', entity: 'chest', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Stomach', entity: 'stomach', bodySection: 'upperBody', hideWhenDesign: {}},
      {label: 'Vest Bottom (Normal Waist)', entity: 'waist', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['high']}},
      {label: 'Vest Bottom (High Waist)', entity: 'waistHigh', bodySection: 'lowerBody', hideWhenDesign: {pantsWaistHeight:['normal']}},
    ],
  },
}

export default garmentAllowance