import React, {useState} from 'react';
import { Button } from 'react-bootstrap'
import styles from './designSidebar.module.css';
import DesignStack from './designStack';

const DesignSidebar = ({item, handleClearDesignClick}) => {
  const [showDesignSidebar, setShowDesignSidebar] = useState(true);

  const handleClick = (e) => {
    setShowDesignSidebar(!showDesignSidebar)  

  }

  return (
    showDesignSidebar ? (
      <div className={showDesignSidebar ? styles.footer : styles.footerHide}>
        <Button className={'my-2 mx-2'} onClick={handleClick}><span className={`oi oi-fullscreen-exit`}></span> Design</Button>
        <Button className={'my-2 mx-2'} onClick={handleClearDesignClick} data-item={JSON.stringify(item)} variant="danger">Clear Design</Button>
        <DesignStack 
          show={showDesignSidebar}
          item={item}
          vertical                     
        />
        
      </div>
    ) : (
      <div className={showDesignSidebar ? styles.footer : styles.footerHide} onClick={handleClick}><Button><span className={`oi oi-fullscreen-enter`}></span> Design</Button></div>
    )
  )
}

export default DesignSidebar