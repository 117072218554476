import React, {useState, useEffect} from "react"
import {navigate} from 'gatsby';
import Layout from "../../components/layout"
import {Container, Alert, Button, Row, Col, Form, Tab, Tabs} from 'react-bootstrap'
import SEO from "../../components/seo";
import Loader from '../../components/loading';
import GenericPagination from '../../components/genericPagination';
import AddCustomer from '../../components/customers/addCustomer';
import StandardModal from '../../components/modal';
import Receipt from '../../components/receipt/receipt';

import Fetch from "../../helpers/fetch";
import { isAdmin, isConsultant, getUsernameFromToken, isTailor } from '../../helpers/roles'
import { isProcessStarted } from '../../helpers/statusHelpers';
import GarmentAllowanceTab from "../../components/garmentAllowanceTab";
import SalesSummary from "../../components/salesSummary";
import CustomerMeasurement from "../../components/customerMeasurement";
import SalesItems from "../../components/salesItems";
import DesignTab from "../../components/designTab";
import BodyPosture from "../../components/bodyPosture";
import constants from '../../helpers/constants';
import { 
  DDMMYYYYFormat, 
  getLocaleMySQLDateFormatFromUTCDate, 
} from '../../helpers/dateTime';
import { extractMeasurementObject, emptyLowerBody, emptyCommon, emptyUpperBody, upperBodyMeasurementSequence } from '../../helpers/extractMeasurement';
import {upperCaserRegex, upperCaser, replacerRegex, replacer} from '../../helpers/string';
import getTotalDesignCost from '../../helpers/getTotalDesignCost';
import { getQueryString } from '../../helpers/queryString'
import { getMeasurementError, measurementLogic, getMeasurementInfo, validateMeasurements } from "../../helpers/measurementValidation";
import {isTrousers, isShirts, isJackets, isVests} from '../../helpers/itemTypeHelpers';
import isElectronicPayment from '../../helpers/electronicPayment';
import isOrderTimeAfterReferralFeature from '../../helpers/isOrderTimeAfterReferralFeature';

import styles from './salesOrder.module.css';

const SalesOrder = ({location}) => {
  const emptyMeasurement = {
    upperBody: emptyUpperBody,
    common: emptyCommon,
    lowerBody: emptyLowerBody,
    consultant_name: '',
    shtCollar: '',
    shtShoulder: '',
    shtChest: '',
    shtWaist: '',
    shtHip: '',
    shtSleeveLength: '',
    shtCuff: '',
    shtArmhole: '',
    shtShoulderSlope: '',
    shtChestFront: '',
    shtChestBack: '',
    shtDoubleCuff: '',
    shtTuckOutLength: '',
    shtShortSleeveLength: '',
    shtShortSleeveCuff: '',
    shtPreferredFit: '',
    shtLength: '',
    shtElbow: '',
    shtBicep: '',
    pantsWaist: '',
    pantsHip: '',
    pantsCrotch: '',
    pantsThigh: '',
    pantsKnee: '',
    pantsCuff: '',
    pantsLowerCuff: '',
    pantsLength: '',
    shortPantsWaist: '',
    shortPantsHip: '',
    shortPantsCrotch: '',
    shortPantsThigh: '',
    shortPantsCuff: '',
    shortPantsLength: '',
    pantPreferredFit: '',
    jcktShoulder: '',
    jcktChest: '',
    jcktWaist: '',
    jcktHip: '',
    jcktSleeveLength: '',
    jcktCuff: '',
    jcktElbow: '',
    jcktLength: '',
    jcktBicep: '',
    jacketArmhole: '',
    jacketShoulderSlope: '',
    jacketChestFront: '',
    jacketChestBack: '',
    jacketPreferredFit: '',
    vestBackLength: '',
    vestShoulder: '',
    vestChest: '',
    vestWaist: '',
    vestHip: '',
    vestPreferredFit: '',
    femaleShoulderToBust: '',
    femaleBustPoints: '',
    femaleBustToWaist: '',
    femaleWaistToHip: '',
    femaleBackLength: '',
    femaleSkirtWaist: '',
    femaleSkirtHip: '',
    femaleSkirtHipHeight: '',
    femaleSkirtLength: '',
    femaleSkirtOpening: '',
  }

  const emptyCustDetails = {
    id: '',
    name:'',
    email: '',
    emailCc: '',
    countryId: '1',
    countryName: 'SINGAPORE',
    phonePrefix: '+65',
    phone: '',
    howYouKnowAboutUs:'',
    howYouKnowAboutUsOthersRemarks:'',
    occupation: '',
    dob:'',
    gender:'',
    postal:'',
    unitNo:'',
    address:'',
    remarks:'',
    smsNotification: true,
    emailNotification: true,
    uplineReferralCode: '',
    personalReferralCode: '',
    ...emptyMeasurement
  }

  const emptySalesItems = [
    {
      item_type:'',
      item_desc:'',
      alteration: false,
      price: 0,
      discount: 0,
      priceAfterDiscount: 0,
      bonusOffset: 0,
      priceAfterBonusOffset: 0,
      urgent_date:'',
      design:'',
      tempId: new Date().getTime(), //temp id
      itemDesignError: true,
    }
  ]

  const emptyTotalAmount = {
    subTotal:0,
    paymentMethod:'',
    discountType:'',
    discountAmount: 0,
    netTotal:0,
    deposit:0,
    balance:0,
    bonusAmountUsed:0,
  }

  const [errMsg, setErrMsg] = useState({})
  
  const [showLoader, setShowLoader] = useState(false);
  const [loaderMsg, setLoaderMsg] = useState('')
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [showCompleteOrderModal, setShowCompleteOrderModal] = useState(false);
  const [showSendReceiptModal, setShowSendReceiptModal] = useState(false);
  const [showRefundItemModal, setShowRefundItemModal] = useState(false);
  const [showCopyDesignModal, setShowCopyDesignModal] = useState(false);
  const [showCopyAllowanceModal, setShowCopyAllowanceModal] = useState(false);
  const [refundRemarks, setRefundRemarks] = useState('')
  const [pagination] = useState({});
  const [rowLimit] = useState(25);

  const [page, setPage] = useState(1)
  const [custDetails, setCustDetails] = useState(emptyCustDetails);
  const [custAge, setCustAge] = useState(0)
  const [measurements, setMeasurements] = useState(emptyMeasurement);
  const [salesItems, setSalesItems] = useState(emptySalesItems);
  const [totalAmount, setTotalAmount] = useState(emptyTotalAmount);
  const [designSections, setDesignSections] = useState([]);
  const [fitPreferences, setFitPreferences] = useState([]);
  const [consultants, setConsultants] = useState('');
  const [orderGroupings, setOrderGroupings] = useState([])
  const [itemTypeChanged, setItemTypeChanged] = useState(false);
  const [selectedConsultant, setSelectedConsultant] = useState('');
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [selectedTabIndex, setselectedTabIndex] = useState(0);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlertMsg, setSuccessAlertMsg] = useState('');
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [failAlertMsg, setFailAlertMsg] = useState('');
  const [key, setKey] = useState('details');
  const [showRemarksAlert, setShowRemarksAlert] = useState(false);
  const [remarksAlertMsg, setRemarksAlertMsg] = useState('');
  const [designSourceTarget, setDesignSourceTarget] = useState({})
  const [linkedList, setLinkedList] = useState({})
  const [overalDesignError, setOverallDesignError] = useState(true);
  const [intervalId, setIntervalId] = useState(null);
  const [autoSaveTimer, setAutoSaveTimer] = useState(30)
  const [autoSaveActive, setAutoSaveActive] = useState(true);
  const [orderHasDisbursedRPoints, setOrderHasDisbursedRPoints] = useState(false);
  const [isReferralActive, setIsReferralActive] = useState(false);
  const [orderTimeAfterReferralFeature, setOrderTimeAfterReferralFeature] = useState(false);
  const [minimumRedemptionAmount , setMinimumRedemptionAmount] = useState(0)

  const reserveOrderId = () => {
    const queryParam = getQueryString(location)
    if (queryParam.orderId) {
      getCustomerDetails();
    } else {
      createEmptyOrderId()
    }
  }

  const createEmptyOrderId = () => {
    const queryParam = getQueryString(location)
    const url=`${constants.BACKEND_URL}/orders/reserve`
    const data={
      custId: queryParam.custId,      
    }
    Fetch.post(url, JSON.stringify(data)).then(res => {
     if (res.error) {

     }
     window.location.href = `/orders/salesOrder?custId=${queryParam.custId}&orderId=${res.id}`
     return;
    })
  }

  const getCustomerDetails = () => {
    setShowLoader(true);
    setLoaderMsg('Getting Customer Details');
    const custObj = getQueryString(location)
    const url=`${constants.BACKEND_URL}/customers/${custObj.custId}`
    Fetch.get(url).then(data => {
      if (data.error) {
        resetLoader();
        navigate('/orders/history');
        return;
      }
      if (data.data) {
        data.data.dob = DDMMYYYYFormat(data.data.dob, true)
      }
      setCustAge(data.age);
      setCustDetails(data.data)
      setMeasurements(extractMeasurementObject(data.data))
      const custMeasurement = extractMeasurementObject(data.data);
      getSalesItems(custMeasurement);
      getConsultants()
    })
  }

  const getSalesItems = (custMeasurement) => {
    const custObj = getQueryString(location)
    if (custObj && custObj.orderId) {
      const url=`${constants.BACKEND_URL}/order-items/orderId/${custObj.orderId}/0/999`
      Fetch.get(url).then( async (data) => {
        if (data.error) {
          resetLoader();
          navigate('/orders/history');
          return;
        }
        if (data.data.length === 0) {
          const totalAmountCopy = {...totalAmount}
          getOrder([], custMeasurement, true)
          getRPoints(totalAmountCopy);
          return;
        }
        const newArray = [];
        const fitPreferencesFromDB = await getDistinctFitPreferenceByItemType(data.data);
        if (data.data.length > 0 ) {
          const sections = await getDistinctItemType(data.data);
          for (const item of data.data) {

            // const allowance = item.allowance ? item.allowance : await getDefaultAllowanceForItemTypeFromDistinctFitPreference(item.item_type, fitPreferencesFromDB);
            const allowance = item.allowance ? item.allowance : '';
            const tempId = item.id ? item.id : new Date().getTime();
            const itemDesignError =  await getItemDesignErrorStatus(sections, item)
            const alteration = item.alteration ? item.alteration : false;

            newArray.push({
              ...item,
              tempId,
              allowance,
              designSheetDownloadable: true,
              itemDesignError,
              alteration,
            })
          }
          // Handle overall design error
          // Show warning design on Design Tab title
          setOverallDesignError(false);
          if (newArray.some(item => !isProcessStarted(item) && item.itemDesignError)) setOverallDesignError(true);
          
          // setSalesItems(newArray.sort(sortById).sort(sortBySetType))
          // getDistinctItemType(data.data);
          // getOrder(data.data, custMeasurement);
        }
        setSalesItems(newArray.sort(sortById).sort(sortBySetType))
        getDistinctItemType(data.data);
        getOrder(data.data, custMeasurement, false);
      })
    } else {
      resetLoader();
    }
  }

  const getOrder = (orderItemsList, custMeasurement, newOrder) => {
    setLoaderMsg('Getting Order Details');
    const custObj = getQueryString(location)
    const url=`${constants.BACKEND_URL}/orders/${custObj.orderId}`
    
    Fetch.get(url).then( async (data) => {
      if (data.error) {
        resetLoader();
        navigate('/orders/history');
        return;
      }
      setSelectedConsultant(data.consultant_name)     
      const measurementsCopy = extractMeasurementObject(data)
      if (newOrder) {
        measurementsCopy.lowerBody = custMeasurement.lowerBody;
        measurementsCopy.upperBody = custMeasurement.upperBody;
        measurementsCopy.posture = custMeasurement.posture;
      }
      measurementsCopy.id = data.id
      measurementsCopy.groupingId = data.groupingId
      measurementsCopy.consultant_name = data.consultant_name;
      measurementsCopy.fullyPaid = data.fullyPaid;
      measurementsCopy.electronicTrxId = data.electronicTrxId;
      measurementsCopy.time = data.time;
      const result = await isOrderTimeAfterReferralFeature(data.time)
      if (result) {
        setOrderTimeAfterReferralFeature(true)
      }

      setMeasurements(measurementsCopy);
      if (data && data.hasItemsInActiveFitGtd && data.hasItemsInActiveFitGtd === "1")  {
        setKey('measurement')
      }
      const totalAmountCopy = {...totalAmount}
      totalAmountCopy.paymentMethod = data.paymentMethod;
      totalAmountCopy.balance = data.balance;
      totalAmountCopy.discountType = data.discountType;
      totalAmountCopy.deposit = data.deposit;
      totalAmountCopy.discountAmount = data.discountType === 'Percentage' ? data.discountAmount : getDiscountAmt(orderItemsList);
      totalAmountCopy.refundedAmt = data.refundedAmt;
      totalAmountCopy.bonusAmountUsed = data.bonusAmountUsed;
      // setTotalAmount(totalAmountCopy);
      getRPoints(totalAmountCopy, orderItemsList, data);
      
    })
  }

  const getRPoints = (totalAmountCopy, orderItemsList, orderData) => {
    setLoaderMsg('Getting R Points');
    const custObj = getQueryString(location)
    const url=`${constants.BACKEND_URL}/points-trx/balance/custId/${custObj.custId}`
    Fetch.get(url).then(res => {
     totalAmountCopy.bonusBalance = res && res.data && res.data.balance ? res.data.balance : 0;
     setTotalAmount(totalAmountCopy);
     calculateTotalAmountSubNetTotalAndBalance(orderItemsList, totalAmountCopy)
     getOrderHasDisbursedRPoints(orderData)
     
    })
  }

  const getOrderHasDisbursedRPoints = (orderData) => {
    const custObj = getQueryString(location)
    const url=`${constants.BACKEND_URL}/points-trx/balance/orderId/${custObj.orderId}`
    Fetch.get(url).then(res => {
      if (res.data && res.data.id) setOrderHasDisbursedRPoints(true);
      getReferralFeatureStatus(orderData);
    })
  }

  const getReferralFeatureStatus = (orderData) => {
    setLoaderMsg('Getting Referral Feature Start Date');
    if (!orderData) return;
    const url=`${constants.BACKEND_URL}/settings/referralStartDate`
    Fetch.get(url).then(res => {
     if (res && res.data && res.data.value) {
       if (new Date(orderData.time) > new Date(res.data.value)) setIsReferralActive(true);
     }
     getMinimumRedemptionAmount();
    })
  }

  const getMinimumRedemptionAmount = () => {
    setLoaderMsg('Getting Minimum Redemption Amount');
    const url=`${constants.BACKEND_URL}/settings/minimumRedemption`
    Fetch.get(url).then(res => {
     if (res && res.data && res.data.value) {
       setMinimumRedemptionAmount(res.data.value)
     }
     resetLoader();
    })
  }

  const getDistinctItemType = (salesItems) => {
    const set = new Set();
    salesItems.forEach(item => {
      if(item.item_type === 'OTHERS' || item.item_type === 'ACCESSORY' || item.item_type === 'BESPOKE' ) return;
      set.add(item.item_type);
    })

    const promises = []
    set.forEach(setItem => {
      const url=`${constants.BACKEND_URL}/design-sections/${setItem}`
      promises.push(Fetch.get(url));
    })

    return Promise.all(promises).then(values => {
      setDesignSections(values)
      return values
    })
  }

  const getDistinctFitPreferenceByItemType = async (salesItems) => {
    const set = new Set();
    if (!salesItems) return "";
    salesItems.forEach(item => {
      if(item.item_type === 'OTHERS' || item.item_type === 'ACCESSORY' || item.item_type === 'BESPOKE' ) return;
      set.add(item.item_type);
    })
    
    const promises = []
    set.forEach(setItem => {
      const url=`${constants.BACKEND_URL}/fit-preferences/itemType/${setItem}`
      promises.push(Fetch.get(url));
    })

    const values = Promise.all(promises)
    setFitPreferences(await values);
    return values;
    
  }

  const getConsultants = () => {
    const url=`${constants.BACKEND_URL}/consultants`
    Fetch.get(url).then(data => {
      setConsultants(data)
    })
  }

  const sortBySetType = (a,b) => {
    if (!a.setType || !b.setType) return -1;
    if (a.item_type === 'ACCESSORY') return 1;    // 1 means a must be lower placed compared to any item type being compared
    if (b.item_type === 'ACCESSORY') return -1;   // -1 means b must be lower placed compared to any item type being compared
    if (a.item_type === 'BESPOKE') return 1;      // 1 means a must be lower placed compared to any item type being compared
    if (b.item_type === 'BESPOKE') return -1;     // -1 means b must be lower placed compared to any item type being compared
    if (a.item_type === 'OTHERS') return 1;       // 1 means a must be lower placed compared to any item type being compared
    if (b.item_type === 'OTHERS') return -1;      // -1 means b must be lower placed compared to any item type being compared

    const setTypeA = a.setType ? a.setType : '';
    const setTypeB = b.setType ? b.setType : '';

    let comparison = 0;
    if (setTypeA > setTypeB) {
      comparison = 1;
    } else if (setTypeA < setTypeB) {
      comparison = -1;
    }
    return comparison;
  }

  const sortById = (a,b) => {
    const setTypeA = a.id ? a.id : a.tempId;
    const setTypeB = b.id ? b.id : b.tempId;

    let comparison = 0;
    if (setTypeA > setTypeB) {
      comparison = 1;
    } else if (setTypeA < setTypeB) {
      comparison = -1;
    }
    return comparison;
  }

  const getOrderGroupings = () => {
    const url=`${constants.BACKEND_URL}/order-groupings`
    Fetch.get(url).then(data => {
     setOrderGroupings(data.data)
    })
  }

  const getSubTotal = (salesItems) => {
    let subTotal = 0;
    if (salesItems && Array.isArray(salesItems) && salesItems.length > 0) {
      salesItems.forEach((item) => {
        subTotal += item.refund_status === constants.REFUND_STATUS.APPROVED ? 0 : Number(item.price) + Number(getTotalDesignCost(item));
      })
    }
    return subTotal;
  }

  const getDiscountAmt = (salesItems) => {
    let discountAmount = 0;
    salesItems.forEach((item) => {
      discountAmount += item.refund_status === constants.REFUND_STATUS.APPROVED ? 0 : Number(item.discount);
    })
    return discountAmount.toFixed(2);
  }

  const updateOrder = () => {
    const queryParam = getQueryString(location)
    const data = {
      measurements: {...measurements},
      totalAmount: {...totalAmount},
      orderItems: [...salesItems],
      custDetails: {...custDetails},
      consultantName: getUsernameFromToken()
    };
    setShowLoader(true);

    if (queryParam.orderId) {
      const url = `${constants.BACKEND_URL}/orders/${queryParam.orderId}`;
      Fetch.put(url, JSON.stringify(data)).then(data => {
        if(data.statusCode === 400 || data.statusCode === 500) {
          setShowFailAlert(true);
          setFailAlertMsg([data.error || data.message === 'Internal server error' ? 'Something went wrong updating order' : ''])
          resetLoader();
          return;
        }

        const measurementsCopy = {...measurements}
        measurementsCopy.id = data.result.orderId
        measurementsCopy.groupingId = data.result.groupingId
        setMeasurements(measurementsCopy);

        const salesItemsCopy = data.result.orderItems.map(item => {
          return {
            ...item,
            designSheetDownloadable: true
          }
        }).sort(sortById).sort(sortBySetType);
        setOrderHasDisbursedRPoints(true)
        setSalesItems(salesItemsCopy);
        setItemTypeChanged(false);
        setShowFailAlert(false);
        setShowSuccessAlert(true);
        setSuccessAlertMsg(data.msg)
        resetLoader();
      })

    } else {
      const url=`${constants.BACKEND_URL}/orders`
      Fetch.post(url, JSON.stringify(data)).then(data => {
       if (!data) {
          setShowFailAlert(true);
          setFailAlertMsg([data.msg])
          resetLoader();
          return;
       }
       if (data) {
          setItemTypeChanged(false);
          setShowFailAlert(false)
          setShowSuccessAlert(true);
          setSuccessAlertMsg(data.msg)
          const measurementsCopy = {...measurements}
          measurementsCopy.id = data.result.orderId
          measurementsCopy.groupingId = data.result.groupingId
          measurementsCopy.time = new Date();
          setMeasurements(measurementsCopy);

          const salesItemsCopy = data.result.orderItems.map(item => {
            return {
              ...item,
              designSheetDownloadable: true
            }
          }).sort(sortById).sort(sortBySetType);
          setSalesItems(salesItemsCopy);
          navigate(`/orders/salesOrder?custId=${queryParam.custId}&orderId=${data.result.orderId}`)
       }
       resetLoader();
      })
    }
  }

  const getDeletedState = () => {
    if (measurements && measurements.deleted)  {
      return !!measurements.deleted;
    }
    
    return false;
  }

  const getFullyPaidState = () => {
    if (!isAdmin()) {
      if (measurements && measurements.fullyPaid)  {
        return !!measurements.fullyPaid;
      }
    }
    
    return false;
  }

  const getRefundedState = () => {
    if (measurements && measurements.refunded)  {
      return !!measurements.refunded;
    }
    return false;
  }

  const getHasItemsInActiveFitGtd = () => {
    if (measurements && measurements.hasItemsInActiveFitGtd && measurements.hasItemsInActiveFitGtd === "1")  {
      return true;
    } 
      
    return false;
  }

  const getHasItemsInPendingRefund = () => {
    if (measurements && measurements.hasItemsInPendingRefund && measurements.hasItemsInPendingRefund === "1")  {
      return true;
    } 
      
    return false;
  }

  const getAllItemTypesAreOthers = () => {
    let allItemTypesAreOthers = true;
    if (salesItems.length === 0) allItemTypesAreOthers = false;

    if (salesItems && Array.isArray(salesItems)) {
      salesItems.forEach((item) => {
        if (item.item_type.toUpperCase() !== 'OTHERS' && item.item_type.toUpperCase() !== 'ACCESSORY' ) allItemTypesAreOthers = false;
      })
    }
    return allItemTypesAreOthers
  }

  const calculateDiscountForEachItems = (salesItemsCopy, totalAmountCopy) => {
    const discountType = totalAmountCopy.discountType;
    const discountValue = Number(totalAmountCopy.discountAmount);
    let totalDiscount = Number(totalAmountCopy.discountAmount);
    let totalSales = 0;
    let discountForEachItem = 0;

    salesItemsCopy.forEach(item => {
      totalSales += item.refund_status === constants.REFUND_STATUS.APPROVED || 
                    item.refund_status === constants.REFUND_STATUS.PENDING || 
                    (item.item_type === 'OTHERS' || item.item_type === 'ACCESSORY' || item.item_type === 'BESPOKE') ? 
                    0 : 
                    Number(item.price) + Number(getTotalDesignCost(item));
    })

    salesItemsCopy.map((item, index) => {
      if (item.item_type === 'OTHERS' || item.item_type === 'ACCESSORY' || item.item_type === 'BESPOKE') {
        item.discount = 0;
        item.priceAfterDiscount = item.price;
        return item;
      }

      item.priceAfterDiscount = Number(item.price) + Number(getTotalDesignCost(item)) - Number(item.discount);
      if ((!item.refund_status || item.refund_status === constants.REFUND_STATUS.REJECTED) &&
        (item.discount !== 0 || (item.discount === 0 && item.item_type !== 'OTHERS'))
      ) {
        const itemPrice = Number(item.price) + Number(getTotalDesignCost(item))
        // console.log('itemPrice', itemPrice);
        
        discountForEachItem = discountType === 'Percentage' ?  Number(itemPrice * (discountValue/100)).toFixed(2) : Number((itemPrice/totalSales) * discountValue).toFixed(2);
        if (itemPrice === 0 ) {
          discountForEachItem = 0;
        }

        item.discount = parseFloat(discountForEachItem).toFixed(2);
        if (discountType !== 'Percentage') {
          // console.log('totalDiscount, discountForEachItem', totalDiscount, parseFloat(discountForEachItem));
          const tempTotalDiscount = Number(totalDiscount - parseFloat(discountForEachItem)).toFixed(2);
          // console.log('tempTotalDiscount', tempTotalDiscount);
          
          if (Number(tempTotalDiscount) > 0 && Number(tempTotalDiscount) <= 0.03) { 
            //last item
            item.discount = Number(totalDiscount) 
          } else {
            // Other items
            totalDiscount = Number(tempTotalDiscount)
          }
          // console.log('totalDiscount', totalDiscount);
          
          if (Number(totalDiscount) < 0) { item.discount = parseFloat(discountForEachItem) + totalDiscount; } 
        } 
        item.priceAfterDiscount = Number(item.price) + Number(getTotalDesignCost(item)) - Number(item.discount)
      } 
      return item;
    })
  }

  const calculateRPointsForEachItems = (salesItemsCopy, totalAmountCopy) => {
    const discountType = totalAmountCopy.discountType;
    const bonusValue = Number(totalAmountCopy.bonusAmountUsed ? totalAmountCopy.bonusAmountUsed : 0);
    let totalBonus = Number(totalAmountCopy.bonusAmountUsed ? totalAmountCopy.bonusAmountUsed : 0);
    let totalSales = 0;
    let bonusOffsetForEachItem = 0;

    salesItemsCopy.forEach(item => {
      totalSales += item.refund_status === constants.REFUND_STATUS.APPROVED || 
                    item.refund_status === constants.REFUND_STATUS.PENDING || 
                    (item.item_type === 'OTHERS' || item.item_type === 'ACCESSORY' || item.item_type === 'BESPOKE') ? 
                    0 : 
                    Number(item.price) + Number(getTotalDesignCost(item));
    })

    salesItemsCopy.map((item, index) => {
      if (item.item_type === 'OTHERS' || item.item_type === 'ACCESSORY' || item.item_type === 'BESPOKE') {
        item.bonusOffset = 0;
        item.priceAfterBonusOffset = item.price;
        return item;
      }

      item.priceAfterBonusOffset = Number(item.price) + Number(getTotalDesignCost(item)) - Number(item.bonusOffset);
      if ((!item.refund_status || item.refund_status === constants.REFUND_STATUS.REJECTED) &&
        (item.bonusOffset !== 0 || (item.bonusOffset === 0 && item.item_type !== 'OTHERS'))
      ) {
        const itemPrice = Number(item.price) + Number(getTotalDesignCost(item))
        
        bonusOffsetForEachItem = itemPrice > 0 ? Number((itemPrice/totalSales) * bonusValue).toFixed(2) : 0;
        
        item.bonusOffset = parseFloat(bonusOffsetForEachItem).toFixed(2);
       
        const tempTotalBonus = Number(totalBonus - parseFloat(bonusOffsetForEachItem)).toFixed(2);
        
        if (Number(tempTotalBonus) > 0 && Number(tempTotalBonus) <= 0.03) { 
          //last item
          item.bonusOffset = Number(totalBonus) 
        } else {
          // Other items
          totalBonus = Number(tempTotalBonus)
        }
        // console.log('totalBonus', totalBonus);
        
        if (Number(totalBonus) < 0) { item.bonusOffset = parseFloat(bonusOffsetForEachItem) + totalBonus; } 
        
        item.priceAfterBonusOffset = Number(item.price)  + Number(getTotalDesignCost(item)) - Number(item.bonusOffset)
        item.priceAfterDiscount = item.priceAfterBonusOffset + Number(getTotalDesignCost(item)) - Number(item.discount)
      } 
      return item;
    })
  }

  const calculateTotalAmountSubNetTotalAndBalance = (salesItems, totalAmountCopy) => {
    totalAmountCopy.subTotal = getSubTotal(salesItems);
    // totalAmountCopy.netTotal = totalAmountCopy.subTotal - getDiscountAmt(salesItems);
    
    const discountAmount = Number(totalAmountCopy.discountAmount) ? Number(totalAmountCopy.discountAmount) : 0;
    const bonusAmountUsed = totalAmountCopy.bonusAmountUsed ? totalAmountCopy.bonusAmountUsed : 0 ;
       
    totalAmountCopy.netTotal = totalAmountCopy.subTotal - bonusAmountUsed - discountAmount;

    
    totalAmountCopy.balance = Number(totalAmountCopy.netTotal - totalAmountCopy.deposit).toFixed(2);
  }

  const getSalesItemById = (itemId) => {
    let item = [];
    if (itemId) {
      item = salesItems.find((item) => {
        if (!item.id) {
          return Number(item.tempId) === Number(itemId)
        }
        return item.id === Number(itemId)
      })
    }
    return item
  }

  useEffect(() => {
    reserveOrderId()
    getOrderGroupings();
    // const intervalHandle = setInterval(() => {
    //   setAutoSaveTimer(t=>t-1)
    // }, 1000);
    // setIntervalId(intervalHandle);    

    return () => {
      // clearInterval(intervalHandle);
    }
  },[])

  // useEffect(() => {
  //   autoSave()
  // }, [autoSaveTimer])

  const handleItemChange = (e) => {
    const changedProperty = {}
    const value = e.target.value;
    const property = e.target.dataset.property
    
    switch (property) {
      case 'smsNotification':
      case 'emailNotification':
        changedProperty[property] = !custDetails[property];
        break;
      case 'countryName':
        changedProperty['countryId'] = e.target.dataset.countryId;
        break;
      default:
        changedProperty[property] = value;
        break;
    }    
    setCustDetails({...custDetails, ...changedProperty});
  }

  const saveCustDetails= (e) => {
    const url = `${constants.BACKEND_URL}/customers`
    Fetch.put(`${url}/${custDetails.id}`, JSON.stringify(custDetails))
    .then((data) => {
      if (!data.raw.affectedRows) {
        setShowFailAlert(true)
        setShowSuccessAlert(false);
        setFailAlertMsg(['Unable to update customer details']);
        return;
      }
      setShowFailAlert(false)
      setShowSuccessAlert(true);
      setSuccessAlertMsg('Customer Details Updated');
      getCustomerDetails();
    })

    // setShowAddCustomer(false);
  }

  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const handlePostureChange = (e) => {
    const changedProperty = {}
    const { type, value } = e.target.dataset;
    const obj = { 
      [type]: {
        value,
        label: type.replace(replacerRegex, replacer).replace(upperCaserRegex, upperCaser)
      },
    }
    changedProperty['posture'] = {...measurements['posture'], ...obj  }
    setMeasurements({...measurements, ...changedProperty})
  }

  const handleMeasurementChange = (e) => {
    const changedProperty = {}
    const inputValue = e.target.value;
    const {property, unit} = e.target.dataset
    const section = e.target.dataset && e.target.dataset.section
    
    updateMeasurements(inputValue, section, property, unit)

    const custDetailsCopy = {...custDetails}
    custDetailsCopy.measurements = JSON.stringify({...measurements, ...changedProperty})
    setCustDetails(custDetailsCopy)
  }

  const handleStepByStepMeasurementChange = (_value, _section, _property, _unit) => {
    let value = '';
    switch (_value) {
      case 'backspace':
        if (_property === 'leftShoulderSlope' || _property === 'rightShoulderSlope') {
          value = measurements[_section][_property].split('')  
        } else {
          value = measurements[_section][_property][_unit].split('')
        }
        value.pop()
        value = value.join('')
        break;
      case 'clear':
        value = '';
        break;
      default:
        value = measurements[_section] && 
        measurements[_section][_property] &&
        measurements[_section][_property][_unit] ? 
        measurements[_section][_property][_unit].toString() + _value.toString() : 
        _value.toString();
        break;
    }
    
    updateMeasurements(value, _section, _property, _unit)
  }

  const updateMeasurements = (inputValue, section, property, unit) => {
    const changedProperty = {}

    if (section) {
      // const section = e.target.dataset.section;
      let obj = {}
      changedProperty[section] = {...measurements[section]}

      if (property === 'leftShoulderSlope' || property === 'rightShoulderSlope') {
        obj[property] = inputValue

      } else {
        obj[property] = {
            inch: unit === 'cm' ? Number(inputValue / 2.54).toFixed(2) : inputValue,
            cm: unit === 'inch' ? Number(inputValue * 2.54).toFixed(2) : inputValue,
            error: getMeasurementError(measurements, section, property, inputValue, unit),
            info: getMeasurementInfo(measurements, section, property, inputValue),
        }
      }
      obj = measurementLogic(property, inputValue, changedProperty, section, obj, custDetails.gender)
      changedProperty[section] = {...changedProperty[section], ...obj};

    } else {
      changedProperty[property] = inputValue;
    }
    setMeasurements({...measurements, ...changedProperty})

  }

  const handleAddLine = (e) => {
    const custObj = getQueryString(location);
    const newLine = {
      item_type:'',
      item_desc:'',
      price: 0,
      discount: 0,
      priceAfterDiscount: 0,
      urgent_date: null,
      order_id: custObj.orderId,
      design:'',
      allowance: '',
      tempId: new Date().getTime(), //temp id
      setType: 'A',
    }
    
    if (!salesItems) {
      setSalesItems([newLine])
    } else {
      setSalesItems([...salesItems, newLine]);
    }
    setItemTypeChanged(true);
    // setOverallDesignError(true);
  }

  const handleSetTypeChange = (e) => {
    const salesItemsCopy = [...salesItems];
    salesItemsCopy[e.target.dataset.index]['setType'] = e.target.value;
    setSalesItems(salesItemsCopy);
  }

  const handleItemTypeChange = async (e) => {
    const salesItemsCopy = [...salesItems];
    const totalAmountCopy= {...totalAmount};

    const itemType = e.target.value;
    const index = e.target.dataset.index;
    
    salesItemsCopy[index]['item_type'] = itemType;
    // salesItemsCopy[index]['allowance'] = await getDefaultAllowanceForItemType(itemType, salesItemsCopy);
    salesItemsCopy[index]['allowance'] = '';    
    salesItemsCopy[index]['design'] = await getDefaultDesignForItemType(itemType);
    salesItemsCopy[index]['itemDesignError'] = false;
    salesItemsCopy[index]['alteration'] = false;
    if (isTrousers(itemType) || isShirts(itemType) || isJackets(itemType) || isVests(itemType)) {
      salesItemsCopy[index]['itemDesignError'] = await getItemDesignErrorStatus(designSections, salesItemsCopy[index]);
    } 
    // Handle overall design error
    // Show warning design on Design Tab title
    setOverallDesignError(false);
    if (salesItemsCopy.some(item => item.itemDesignError)) setOverallDesignError(true);
    calculateDiscountForEachItems(salesItemsCopy, totalAmountCopy);
    getDistinctItemType(salesItemsCopy);
    await getDistinctFitPreferenceByItemType(salesItemsCopy)
    setItemTypeChanged(true);
    setSalesItems(salesItemsCopy);
  }

  const getDefaultDesignForItemType = async (_itemType) => {
    const designObj = {};
    const url=`${constants.BACKEND_URL}/design-options/default/${_itemType}`
    const res = await Fetch.get(url)
    if (res) {
      res.forEach(option => {
        designObj[option.sectionIdentifier] = { 
          optionIdentifier: option.optionIdentifier,
          optionPrice: option.optionPrice,
          sectionDesc: option.sectionDesc,
          optionDesc: option.optionDesc,
          overlayImageUrl: option.overlayImageUrl,
          forFrontview: option.forFrontView ? "true" : "false",
          forBackview: option.forBackView ? "true" : "false",
          kutesmartCode: option.kutesmartCode,
          defaultOption: option.defaultOption
        }
      })
      return JSON.stringify(designObj);
    }
  }  
  
  const getDefaultAllowanceForItemType = async (itemType, salesItemsCopy = null, fitType = null) => {
    const fitPreferencesFromDB = await getDistinctFitPreferenceByItemType(salesItemsCopy);
    return getDefaultAllowance(itemType, fitPreferencesFromDB, fitType)
  }

  const getDefaultAllowanceForItemTypeFromDistinctFitPreference = (itemType, fitPreferencesFromDB, fitType = null) => {
    return getDefaultAllowance(itemType, fitPreferencesFromDB, fitType)
  }

  const getDefaultAllowance = (itemType, fitPreferencesFromDB, fitType) => {
    if (!fitPreferencesFromDB) return "";
    const itemTypePreference = fitPreferencesFromDB.filter(item => {
      return item.itemType === itemType;
    })

    if (itemTypePreference && itemTypePreference.length > 0) {
      const itemTypePreferenceData = itemTypePreference[0].data;
      if (itemTypePreferenceData && itemTypePreferenceData.length === 0) return "";
      const firstFitType = fitType ? fitType : itemTypePreferenceData[0].fitType;
      const defaultPreferences = itemTypePreferenceData.filter(item => {
        return item.fitType === firstFitType
      })

      const obj = {}
      obj['itemFitPreference'] = fitType ? fitType : firstFitType;
      defaultPreferences.forEach(item =>{
        let minProperty = {} 
        let min = Number(item.allowanceDefault) - 2 >= 0 ? Number(item.allowanceDefault) - 2 : 0;
        if (item.measurementPoint.indexOf('Wrist') !== -1) {
            min = Number(item.allowanceDefault)
        } else if (item.measurementPoint === 'lapel') {
            min = Number(item.allowanceDefault) - 1
        }
        if (isTrousers(itemType)) {
          if (item.measurementPoint.indexOf('waist') !== -1) {  
            min = -2
          }
        }

        if ((itemType === 'LP' || itemType === 'PRIMO LP') && item.measurementPoint === 'cuff') {
          min = -2;
        }
        if ((itemType === 'LP' || itemType === 'PRIMO LP') && item.measurementPoint === 'lpLength') {
          min = -2;
        }

        minProperty = {
          min
        }
        obj[item.measurementPoint] = {
          inch: item.allowanceDefault,
          cm: Number(item.allowanceDefault) * 2.54,
          max: Number(item.allowanceDefault) + 2,
          ...minProperty,
        }
      })
      return JSON.stringify(obj)
    }
    return "";
  }

  const handleFabricChange = (e) => {
    const salesItemsCopy = [...salesItems];
    salesItemsCopy[e.target.dataset.index]['item_desc'] = e.target.value;
    setSalesItems(salesItemsCopy);
  }

  const handleAlterationChange = (e) => {
    const salesItemsCopy = [...salesItems];    
    salesItemsCopy[e.target.dataset.index]['alteration'] = e.target.checked;
    setSalesItems(salesItemsCopy);
  }

  const handlePriceChange = (e) => {
    const salesItemsCopy = [...salesItems];
    const totalAmountCopy= {...totalAmount};
    const measurementsCopy = {...measurements};
    const property = e.target && e.target.dataset && e.target.dataset.property ? e.target.dataset.property : 'others';
    switch (property) {
      case 'paymentMethod':
        totalAmountCopy.paymentMethod = e.target.value;
        measurementsCopy.paymentMethod = e.target.value;
        break;
      case 'discountType':
        totalAmountCopy.discountType = e.target.value;
        measurementsCopy.discountType = e.target.value;
        if (e.target.value === 'Nil') {
          totalAmountCopy.discountAmount = 0;
          measurementsCopy.discountAmount = 0;
        }
        break;
      case 'discountAmt':
        totalAmountCopy.discountAmount = e.target.value;
        measurementsCopy.discountAmount = e.target.value;
        break;
      case 'deposit':
        totalAmountCopy.deposit = e.target.value;
        measurementsCopy.deposit = e.target.value;
        break;
      case 'electronicTrxId':
        // totalAmountCopy.electronicTrxId = e.target.value;
        measurementsCopy.electronicTrxId = e.target.value;
        break;
      case 'bonusAmountUsed':
        // totalAmountCopy.electronicTrxId = e.target.value;
        totalAmountCopy.bonusAmountUsed = e.target.value;
        break;
      
      default:
        salesItemsCopy[e.target.dataset.index]['price'] = e.target.value;
        break;
    }
    calculateRPointsForEachItems(salesItemsCopy, totalAmountCopy)
    calculateDiscountForEachItems(salesItemsCopy, totalAmountCopy);
    calculateTotalAmountSubNetTotalAndBalance(salesItemsCopy, totalAmountCopy);
    
    measurementsCopy.balance = Number(totalAmountCopy.netTotal - totalAmountCopy.deposit).toFixed(2);
    measurementsCopy.gstAmount = (7/100) * Number(totalAmountCopy.netTotal)

    setMeasurements(measurementsCopy);
    setTotalAmount(totalAmountCopy);
    setSalesItems(salesItemsCopy);
  }

  const handleUrgentDateChange = (date, index) => {
    const salesItemsCopy = [...salesItems];
    salesItemsCopy[index].urgent_date = getLocaleMySQLDateFormatFromUTCDate(date);
    setSalesItems(salesItemsCopy);
  }

  const handleOnDeleteRowClick = (e) => {
    const salesItemsCopy = [...salesItems];
    const totalAmountCopy= {...totalAmount};
    const measurementsCopy = {...measurements};
    const index = e.target.dataset.index;
    salesItemsCopy.splice(index,1);
    totalAmountCopy.discountAmount = totalAmountCopy.discountType === 'Percentage' ? totalAmountCopy.discountAmount : getDiscountAmt(salesItemsCopy);

    calculateTotalAmountSubNetTotalAndBalance(salesItemsCopy, totalAmountCopy);
    
    measurementsCopy.balance = Number(totalAmountCopy.netTotal - totalAmountCopy.deposit).toFixed(2);
    measurementsCopy.gstAmount = (7/100) * Number(totalAmountCopy.netTotal)

    // Handle overall design error
    // Show warning design on Design Tab title
    setOverallDesignError(false);
    if (salesItemsCopy.some(item => item.itemDesignError)) setOverallDesignError(true);

    setItemTypeChanged(true);
    setMeasurements(measurementsCopy);
    setTotalAmount(totalAmountCopy);
    setSalesItems(salesItemsCopy);
  }

  const handleOnSaveOrderClick = () => {
    let errMessagesArray = [];
    let priceCantAllBeZeroError = true;
    let allDesignItemsAreSet = true;
    const itemsNeedDesignFixArray = [];
    let bespokeCount = 0;
    let othersCount = 0;
    let garmentCount = 0;
    salesItems.forEach((item, index) => {
      if (item.item_type == '') {
        errMessagesArray.push(`Item Type missing at Item ${index+1}`)
      }

      if(item.item_type !== '' && (item.item_type !== 'BESPOKE' && item.item_type !== 'ALTER' && item.item_type !== 'OTHERS') && Number(item.price) !== 0) {
        priceCantAllBeZeroError = false;        
      }

      if (isTrousers(item.item_type) || isShirts(item.item_type) || isJackets(item.item_type) || isVests(item.item_type)) { 
        if (item.item_desc === '') {
          errMessagesArray.push(`Fabric Type missing at Item ${index+1}`)
        }

        if (item.alteration && Number(item.price) === 0) {
          priceCantAllBeZeroError = false;  
        }

        if (!isProcessStarted(item) && !item.alteration && item.itemDesignError) {
          allDesignItemsAreSet = false;
          itemsNeedDesignFixArray.push(index+1)
        }
        garmentCount++;

      }
      if (item.item_type === 'BESPOKE' ) {
        bespokeCount++;
      } 
      if (item.item_type === 'OTHERS') {
        othersCount++;
      } 
      
    })

    if (bespokeCount > 0 && bespokeCount > garmentCount) {
      errMessagesArray.push(`Number of items for BESPOKE does not match the number of garments`);
    }
    

    if (othersCount > 0 && garmentCount === 0) {
      errMessagesArray.push(`Can not have OTHERS without any garment`);
    }

    if(totalAmount.discountType !== '' && Number(totalAmount.discountAmt) === 0 ) {
      errMessagesArray.push('Discount Amount missing')
    }

    if(totalAmount.paymentMethod === '') {
      errMessagesArray.push('Payment Method is missing')
    }

    if(Number(totalAmount.deposit) <= 0 && Number(totalAmount.netTotal) !== 0) {
      // errMessagesArray.push('Please fill deposit/payment.')
    }

    if (isElectronicPayment(totalAmount) && (measurements.electronicTrxId === '' || measurements.electronicTrxId === null)) {
      errMessagesArray.push('Please enter Electronic Trx Id for audit purposes')
    }

    if (totalAmount.bonusAmountUsed > 0 && totalAmount.bonusAmountUsed < 30 && totalAmount.refundedAmt === 0) {
      errMessagesArray.push(`Minimum redemption is ${minimumRedemptionAmount} R Points`)
    }
    
    if (totalAmount.bonusAmountUsed > totalAmount.subTotal) {
      errMessagesArray.push(`Can't use more R Points than Total Price`)
    }

    if (priceCantAllBeZeroError) {
      errMessagesArray.push(`Price can't be all zero`)
    }
    if(totalAmount.balance < 0) {
      errMessagesArray.push('There is an over payment. Please ensure is balance amount is correct before proceeding.')
    }
    if (isConsultant() || isTailor()) {
      measurements.consultant_name = getUsernameFromToken().replace(upperCaserRegex, upperCaser);
    }
    if (!measurements.consultant_name || measurements.consultant_name === '') {
      errMessagesArray.push('Please select a consultant')
    }
    if (!allDesignItemsAreSet) {
      errMessagesArray.push(`Please fix designs for item ${itemsNeedDesignFixArray.join(', ')}`)
    }
    const measurementValidationMessages = validateMeasurements(measurements, salesItems, custDetails.gender).filter(item =>  { if (item) return item})
    const newArray = errMessagesArray.concat(measurementValidationMessages)

    if (newArray && newArray.length > 0) {
      setShowSuccessAlert(false);
      setShowFailAlert(true);
      setFailAlertMsg(newArray)
      return;
    }
    updateOrder();
  }
  
  const handleOnContinueClick = () => {
    switch (key) {
      case 'details':
        setKey('items')
        break;
      case 'items':
        setKey('measurement')
        break;
      case 'measurement':
        setKey('design')
        break;
      case 'design':
        setKey('garmentAllowance')
        break;
      case 'garmentAllowance':
        setKey('summary')
        break;
      default:
        break;
    }
  }

  const handleOnBackClick = () => {
    switch (key) {
      case 'items':
        setKey('details')
        break;
      case 'measurement':
        setKey('items')
        break;
      case 'design':
        setKey('measurement')
        break;
      case 'garmentAllowance':
        setKey('design')
        break;
      case 'summary':
        setKey('garmentAllowance')
        break;
      default:
        break;
    }
  }

  const handleOnConsultantChange = (e) => {
    const measurementsCopy = {...measurements};
    measurementsCopy.consultant_name = e.target.value
    setSelectedConsultant(e.target.value)
    setMeasurements(measurementsCopy);
  }

  const handleOnPrintReceiptClick = () => {
    setShowReceiptModal(true);
  }

  const handleReceipModalClose = () => {
    setShowReceiptModal(false);
  }

  const handleOnSendReceiptClick= () => {
    setShowSendReceiptModal(true);
  }

  const printDiv = () => {
    const divName = 'invoice-wrapper'
    const element = document.getElementById(divName);
    
    if (element) {
      var printContents = document.getElementById(divName).innerHTML;
      var myWindow = window.open();
      myWindow.document.write('<html><head><title></title></head><body>');
      myWindow.document.write(printContents+'<script type="text/javascript">window.document.close();window.print();<'+'/script> ');
      myWindow.document.write('</body></html>');
      myWindow.close();
      return true;
    }
    return false;
    //document.body.innerHTML = originalContents;
  }

  const handleOnCompleteOrderClick = () => {
    setShowCompleteOrderModal(true);
  }

  const handleCompleteOrderClose = () => {
    setShowCompleteOrderModal(false);
  }

  const handleCompleteOrderConfirm = () => {
    if (getAllItemTypesAreOthers()) {
      setShowSuccessAlert(false);
      setShowFailAlert(false)
      setShowCompleteOrderModal(false);
      closeOrder();
      return;
    }
    setShowCompleteOrderModal(false);
    setFailAlertMsg([`Some item types are not "OTHERS"`])
    setShowFailAlert(true)
  }

  const closeOrder = () => {
    const url=`${constants.BACKEND_URL}/orders/close/${measurements.id}`
    const data={}
    Fetch.post(url, JSON.stringify(data)).then(data => {
    //  const measurementsCopy = {...measurements}
    //  measurementsCopy.fullyPaid = data.fullyPaid
    //  measurementsCopy.balancePaid = data.balancepaid
    //  setMeasurements(measurementsCopy);
     setShowCompleteOrderModal(false);
     navigate('/orders/history') ;
    })
  }

  const handleSendReceiptClose = () => {
    setShowSendReceiptModal(false);
  }

  const handleSendReceiptConfirm = (e) => {
    const data={
      measurements,
      custDetails,
      totalAmount,
      salesItems,
    }
    sendEmailReceipt(data)
  }

  const handleReceiptSecondaryConfirm = () => {
    //send to group's main contact
    const sendToGroupMainContact = true;
    const data={
      measurements,
      custDetails,
      totalAmount,
      salesItems,
      sendToGroupMainContact,
    }
    sendEmailReceipt(data)
  }

  const sendEmailReceipt = (data) => {
    const queryParam = getQueryString(location)
	console.log('orderID::: ' + data.measurements.id);
	console.log('custID::: ' + data.custDetails.id);
    const url=`${constants.BACKEND_URL}/orders/emailReceipt/${data.measurements.id}`
    setShowSendReceiptModal(false);
    setShowLoader(true)
    Fetch.post(url, JSON.stringify(data)).then(data => {
      if (!data.status) {
        setShowFailAlert(true)
        setShowSuccessAlert(false);
        setFailAlertMsg(['Unable to send Receipt']);
        resetLoader();
        return;
      }
      resetLoader();
      setShowFailAlert(false)
      setShowSuccessAlert(true);
      setSuccessAlertMsg('Receipt Sent');
    })
  }

  const handleOnRefundRowClick = (e) => {
    setSelectedItemIndex(e.target.dataset.index);
    setShowRefundItemModal(true);
    setRefundRemarks('');
  }

  const handleRefundItemClose = () => {
    setShowRefundItemModal(false);
  }

  const handleOnRefundRemarksChange = (e) => {
    setRefundRemarks(e.target.value);
  }
  
  const handleRefundItemConfirm = () => {
    if (!refundRemarks || refundRemarks.trim() === '') {
      setShowRemarksAlert(true);
      setRemarksAlertMsg('Refund reason can not be empty');
      return;
    }
    setShowRemarksAlert(false);
    const url=`${constants.BACKEND_URL}/order-items/item/${salesItems[selectedItemIndex].id}`
    const data={
      refundStatus: constants.REFUND_STATUS.PENDING,
      refundRemarks
    }
    Fetch.patch(url, JSON.stringify(data)).then(data => {
      if (!data.status) {
        setShowSuccessAlert(false);
        setShowFailAlert(true);
        setFailAlertMsg(['Unable to activate refund process']);
        setShowRefundItemModal(false);
        return;
      }

      patchStatusChangeLog()
      const salesItemsCopy = [...salesItems];
      const measurementsCopy = {...measurements};
      
      salesItemsCopy[selectedItemIndex].refund_status = constants.REFUND_STATUS.PENDING;
      salesItemsCopy[selectedItemIndex].refund_remarks = refundRemarks;
      measurementsCopy.hasItemsInPendingRefund = "1";
      setSalesItems([...salesItemsCopy]);
      setMeasurements({...measurementsCopy});

      updateOrder();
      setShowFailAlert(false);
      setShowSuccessAlert(true);
      setSuccessAlertMsg('Refund process activated for chosen item');
      setShowRefundItemModal(false);
    })
  }

  const patchStatusChangeLog = () => {
    const url=`${constants.BACKEND_URL}/order-items/item/${salesItems[selectedItemIndex].id}/log`
    const refundData={
      status: 'Pending Refund'
    }
    Fetch.patch(url, JSON.stringify(refundData)).then(refundData => {
      // console.log('refundData', refundData);
    })
  }

  const handleDesignOptionChange = async (e) => {
    const totalAmountCopy = {...totalAmount};
    let dataset = e.target.dataset

    const {itemId, sectionIdentifier, sectionDesc, optionDesc, optionIdentifier, optionPrice, optionKutesmartCode, overlayImageUrl, sectionForBackview, sectionForFrontview} = dataset;
    const salesItemsCopy = [...salesItems];
    const index = salesItemsCopy.findIndex((item) => Number(item.tempId ? item.tempId : item.id) === Number(itemId))
    
    if (index !== -1) {
      const itemToChange = salesItemsCopy[index];
      const designObj = itemToChange.design ? JSON.parse(itemToChange.design) : {};
      const isSame = isSameDesignForSectionAndOption(designObj, sectionIdentifier, optionIdentifier, optionPrice)
      if (!isSame) {
        itemToChange.designSheetDownloadable = false;
      }

      designObj[sectionIdentifier] = { 
        optionIdentifier,
        optionPrice,
        sectionDesc,
        optionDesc,
        overlayImageUrl,
        forFrontview: sectionForFrontview,
        forBackview: sectionForBackview,
        kutesmartCode: optionKutesmartCode,
      }
      const newDesign = JSON.stringify(designObj);
      itemToChange.design = newDesign;
      itemToChange.designCost = getTotalDesignCost(itemToChange)

      if (sectionIdentifier === 'pleats') {
        itemToChange.allowance = "";
      }

      // Handle Item Design Error
      // Show warning design on Individual Item Design Tab title
      
      itemToChange.itemDesignError = false;
      itemToChange.itemDesignError = await getItemDesignErrorStatus(designSections, itemToChange);
      
      // Handle overall design error
      // Show warning design on Design Tab title
      setOverallDesignError(false);
      if (salesItemsCopy.some(item => item.itemDesignError)) setOverallDesignError(true);

      calculateDiscountForEachItems(salesItemsCopy, totalAmountCopy);
      calculateTotalAmountSubNetTotalAndBalance(salesItemsCopy, totalAmountCopy);
      setSalesItems(salesItemsCopy)
      setTotalAmount(totalAmountCopy);
    }
  }
  
  const getItemDesignErrorStatus = async (designSections, item) => {
  
    const url=`${constants.BACKEND_URL}/design-sections/${item.item_type}`
    const res = await Fetch.get(url);
    
    if (res && res.sections) {
      const sectionArray = res.sections;
      
      if (sectionArray) {
        const sections = sectionArray;
        const designObj = item.design ? JSON.parse(item.design) : {};
        for (const section of sections) {
          if (designObj[section.sectionIdentifier]) continue;
          return true; //means design is missing
        }
      }
      return false;
    }

    return false;
    
  }
  
  const handleDesignRemarksChange = (e) => {
    let dataset = e.target.dataset
    const {itemId} = dataset;
    const salesItemsCopy = [...salesItems];
    const index = salesItemsCopy.findIndex((item) => Number(item.tempId ? item.tempId : item.id) === Number(itemId))
    if (index !== -1) {
      const itemToChange = salesItemsCopy[index];
      const designObj = itemToChange.design ? JSON.parse(itemToChange.design) : {};
      designObj.remarks = e.target.value;
      const newDesign = JSON.stringify(designObj);
      itemToChange.design = newDesign;
      setSalesItems(salesItemsCopy)
    }
  }

  const handleMeasurementRemarksChange = (e) => {
    const measurementsCopy = {...measurements};
    measurementsCopy['upperBody']['remarks'] = e.target.value;
    setMeasurements(measurementsCopy)
  }

  const isSameDesignForSectionAndOption = (designObj, sectionIdentifier, optionIdentifier, optionPrice) => {
    if (Object.keys(designObj).length > 0 && designObj[sectionIdentifier]) {
      if (
          designObj[sectionIdentifier]['optionIdentifier'] === optionIdentifier &&
          Number(designObj[sectionIdentifier]['optionPrice']) === Number(optionPrice)  
      ) {
        return true;
      }
      return false;
    }
  }
  
  const handleDuplicateDesign = ({sourceTempId, targetTempId}) => {
    setShowCopyDesignModal(true);
    setDesignSourceTarget({
      sourceTempId, 
      targetTempId
    })
    
  }

  const handleCopyDesignConfirm = async () => {
    if(designSourceTarget.sourceTempId === designSourceTarget.targetTempId) return;
    const salesItemsCopy = [...salesItems];
    const sourceIndex = salesItemsCopy.findIndex((item) => Number(item.tempId) === Number(designSourceTarget.sourceTempId) || Number(item.id) === Number(designSourceTarget.sourceTempId));
    const targetIndex = salesItemsCopy.findIndex((item) => Number(item.tempId) === Number(designSourceTarget.targetTempId) || Number(item.id) === Number(designSourceTarget.targetTempId));
    salesItemsCopy[targetIndex].design = salesItemsCopy[sourceIndex].design;

    salesItemsCopy[targetIndex].itemDesignError = false;
    salesItemsCopy[targetIndex].itemDesignError = await getItemDesignErrorStatus(designSections, salesItemsCopy[targetIndex]);

    // Handle overall design error
    // Show warning design on Design Tab title
    setOverallDesignError(false);
    if (salesItemsCopy.some(item => item.itemDesignError)) setOverallDesignError(true);

    setSalesItems(salesItemsCopy);
    setShowCopyDesignModal(false);
  }

  const handleCopyDesignClose = () => {
    setShowCopyDesignModal(false);
  }

  const handleAllowanceChange = (e) => {
    const inputValue = e.target.value
    const {itemId, element, unit} = e.target.dataset;

    const salesItemsCopy = [...salesItems];
    const index = salesItemsCopy.findIndex((item) => Number(item.tempId ? item.tempId : item.id) === Number(itemId))

    if (index !== -1) {
      const allowanceObj = salesItemsCopy[index]['allowance'] ? JSON.parse(salesItemsCopy[index]['allowance']) : {};
      allowanceObj[element] = {
        inch: unit === 'cm' ? Number(inputValue / 2.54).toFixed(2) : inputValue,
        cm: unit === 'inch' ? Number(inputValue * 2.54).toFixed(2) : inputValue
      }
      const newAllowance = JSON.stringify(allowanceObj);
      salesItemsCopy[index].allowance = newAllowance;
      setSalesItems(salesItemsCopy)
    }
  }

  const handleDuplicateAllowance = ({sourceTempId, targetTempId}) => {
    setShowCopyAllowanceModal(true);
    setDesignSourceTarget({
      sourceTempId, 
      targetTempId
    })
  }

  const handleCopyAllowanceConfirm = () => {
    if(designSourceTarget.sourceTempId === designSourceTarget.targetTempId) return;
    const salesItemsCopy = [...salesItems];
    const sourceIndex = salesItemsCopy.findIndex((item) => Number(item.tempId) === Number(designSourceTarget.sourceTempId) || Number(item.id) === Number(designSourceTarget.sourceTempId))  ;
    const targetIndex = salesItemsCopy.findIndex((item) => Number(item.tempId) === Number(designSourceTarget.targetTempId) || Number(item.id) === Number(designSourceTarget.targetTempId));
    salesItemsCopy[targetIndex].allowance = salesItemsCopy[sourceIndex].allowance;
    setSalesItems(salesItemsCopy);
    setShowCopyAllowanceModal(false);
  }

  const handleCopyAllowanceClose = () => {
    setShowCopyAllowanceModal(false);
  }

  const handleOnDownloadClick = (e) => {
    const {itemId, itemType, itemDesc, orderId, blank, measurementNumbers, kutesmart, garmentMeasurement} = e.target.dataset;
    setShowLoader(true)
    downloadDesignPDF(custDetails.id, orderId, itemId, itemType, itemDesc, blank, measurementNumbers, kutesmart, garmentMeasurement)
  }

  const downloadDesignPDF = (custId, orderId, itemId, itemType, itemDesc, isBlank=false, measurementNumbers=false, kutesmart=false, garmentMeasurement=false) => {
    const url=`${constants.BACKEND_URL}/design-options/cust/${custId}/item/${itemId}/type/${itemType}/download`
    let data={}
    if (isBlank) {
      data = { 
        isBlank,
        measurementNumbers,
        kutesmart,
        garmentMeasurement,
      }
    }
    
    Fetch.post(url, JSON.stringify(data))
      .then(async (res) => {
        const reader = res.getReader();
        return new ReadableStream({
          async start(controller) {
            while (true) {
              const { done, value } = await reader.read();
              // When no more data needs to be consumed, break the reading
              if (done) {
                break;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
            }
            // Close the stream
            controller.close();
            reader.releaseLock();
          }
        })
      })
      // Create a new response out of the stream
      .then(rs => new Response(rs))
      // Create an object URL for the response
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      // Update image
      .then(url => {
        const fileName = `${custId}_${orderId}_${itemId}_${itemType}_${itemDesc}.pdf`
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent('click'));
        resetLoader();
      })
      .catch(e => {
        resetLoader();
        console.error(e);
      });
  }

  const handleOnDownloadAllOrdersClick = (e) => {
    setShowLoader(true);
    salesItems.forEach(item => {
      if (item.item_type === 'OTHERS' || item.item_type === 'BESPOKE' || item.item_type === 'ACCESSORY') return;
      downloadDesignPDF(custDetails.id, item.order_id, item.id, item.item_type, item.item_desc)
    });
  }

  const handleFitPreferenceChange = async (e) => {
    const fitType = e.target.value;
    const {itemId: id} = e.target.dataset
    const salesItemsCopy = [...salesItems];
    const index = salesItemsCopy.findIndex((item) => Number(item.tempId ? item.tempId : item.id) === Number(id))
    salesItemsCopy[index].allowance = await getDefaultAllowanceForItemType(salesItemsCopy[index].item_type, salesItemsCopy, fitType);
    setSalesItems(salesItemsCopy);
  }

  const addAllowance = (e) => {
    const { allowancePoint, id } = e.target.dataset;
    handleAddOrReduceAllowance(id, allowancePoint, 'add')
  }

  const reduceAllowance = (e) => {
    const { allowancePoint, id } = e.target.dataset;
    handleAddOrReduceAllowance(id, allowancePoint, 'reduce')
  }

  const handleAddOrReduceAllowance = (id, allowancePoint, action) => {
    const salesItemsCopy = [...salesItems];
    const index = salesItemsCopy.findIndex((item) => Number(item.tempId ? item.tempId : item.id) === Number(id))
    
    const allowanceObj = JSON.parse(salesItemsCopy[index].allowance)

    if (!allowanceObj[allowancePoint]) {
      allowanceObj[allowancePoint] = {
        inch: 0,
        cm: 0,
        min: 0,
        max: 2,
      }
    }

    // SET NEW MIN FOR PREVIOUS ALLOWANCE
    // THIS IS TO HANDLE OLD ALLOWANCES WHERE MIN IS 0 AND NEEDS TO CHANGE TO -2 
    // TO ALLOW TOTAL MEASUREMENT REDUCTION
    const itemType = salesItemsCopy[index].item_type;
    if ((itemType === 'LP' || itemType === 'PRIMO LP') && allowancePoint === 'cuff') {
      allowanceObj[allowancePoint]['min'] = -2;
    }
    if ((itemType === 'LP' || itemType === 'PRIMO LP') && allowancePoint === 'lpLength') {
      allowanceObj[allowancePoint]['min'] = -2;
    }


    switch (action) {
      case 'add':
        if (Number(allowanceObj[allowancePoint]['inch']) + 0.25 <= Number(allowanceObj[allowancePoint]['max']))
          allowanceObj[allowancePoint]['inch'] = Number(allowanceObj[allowancePoint]['inch']) + 0.25
        break;
      case 'reduce':
        if (Number(allowanceObj[allowancePoint]['inch']) - 0.25 >= Number(allowanceObj[allowancePoint]['min']))
          allowanceObj[allowancePoint]['inch'] = Number(allowanceObj[allowancePoint]['inch']) - 0.25
        break;
    
      default:
        break;
    }
    allowanceObj[allowancePoint]['cm'] = Number(allowanceObj[allowancePoint]['inch']) * 2.54
    salesItemsCopy[index].allowance = JSON.stringify(allowanceObj);
    setSalesItems(salesItemsCopy);
  }
  
  const handleOrderGroupingsChange = (e) => {
    const changedProperty = {}
    const value = e.target.value;
    changedProperty['groupingId'] = value;
    setMeasurements({...measurements, ...changedProperty})
    
  }

  const handleItemsTabClick = (e) => {
    setSelectedItemIndex(e);
  }

  const resetLoader = () => {
    setShowLoader(false);
    setLoaderMsg('');
  }

  const autoSave = () => {
    if (autoSaveTimer === 0) {
      handleOnSaveOrderClick();
      setAutoSaveTimer(30);
    }
  }

  const handleAutoSaveChange = () => {
    setAutoSaveActive(!autoSaveActive)
    if (autoSaveActive) clearInterval(intervalId);
    if (!autoSaveActive) {
      setAutoSaveTimer(30)
      const intervalHandle = setInterval(() => {
        setAutoSaveTimer(t=>t-1)
      }, 1000);
      setIntervalId(intervalHandle); 
    }
  }

  const handleClearDesignClick = async (e) => {
    const {item} = e.target.dataset;
    const itemObj = JSON.parse(item);
    const itemId = itemObj.id || itemObj.tempId;
    const salesItemsCopy = [...salesItems];
    const index = salesItemsCopy.findIndex((item) => Number(item.tempId ? item.tempId : item.id) === Number(itemId))

    salesItemsCopy[index].design = "";

    salesItemsCopy[index].itemDesignError = false;
    salesItemsCopy[index].itemDesignError = await getItemDesignErrorStatus(designSections, salesItemsCopy[index]);

    // Handle overall design error
    // Show warning design on Design Tab title
    setOverallDesignError(false);
    if (salesItemsCopy.some(item => item.itemDesignError)) setOverallDesignError(true);

    setSalesItems(salesItemsCopy);
  }

  return (
    <Layout pageInfo={{ pageName: "Sales Order" }}>
        <Loader show={showLoader} message={loaderMsg}></Loader>
        <SEO title="New/Repeat Order" />
        <Row>
          <Col className="text-center mb-3">
            <h5>New/ Repeat Order</h5>
            
          </Col>
        </Row>
        <Container >
          {/* <div className={styles.timer}>
            <Form.Label>Auto Save</Form.Label>
            <Form.Check
              className="text-center" 
              type="switch"
              id="custom-switch"
              label={autoSaveActive ? 'Yes' : 'No'}
              // data-property="showInDesignSheet" 
              checked={autoSaveActive}
              onChange={handleAutoSaveChange}
            />
            {autoSaveActive && 
              <>
                <hr />
                <div className={styles.counter}>Saving<br />in {autoSaveTimer}s</div>
              </>
            }
          </div> */}

          <Row>
            <Col>
              <Alert variant="success" dismissible onClose={() => setShowSuccessAlert(false)} show={showSuccessAlert}>{successAlertMsg}</Alert>
              <Alert variant="danger" dismissible onClose={() => setShowFailAlert(false)} show={showFailAlert}>
                <ul>
                  {failAlertMsg && Array.isArray(failAlertMsg) ? 
                    (
                      failAlertMsg.map((msg, index) => {
                        return (
                          <li key={index}>{msg}</li>
                        )
                      })
                    ) : <></>
                  }
                </ul>
              </Alert>
            </Col>
          </Row>
          <Tabs defaultActiveKey="details" className="tabs mb-3"  activeKey={key} onSelect={(k) => { setKey(k)}}>
            <Tab eventKey="details" title={<strong>Cust Details</strong>}>
              <AddCustomer 
                show={true} 
                item={custDetails} 
                handleItemChange={handleItemChange}
                saveEditItem={saveCustDetails}
                isDisabled={getRefundedState() || getDeletedState() || getFullyPaidState() }
                showMeasurements={false}
                editCustomer={true}
                setShowFailAlert={setShowFailAlert}
                showFailAlert={false}
                failAlertMsg={failAlertMsg}
              ></AddCustomer>

            </Tab>
            <Tab eventKey="items" title={<strong>Items</strong>}>
              <SalesItems
                salesItems={salesItems}
                measurements={measurements}
                totalAmount={totalAmount}
                handleAddLine={handleAddLine}
                handleSetTypeChange={handleSetTypeChange}
                handleItemTypeChange={handleItemTypeChange}
                handleFabricChange={handleFabricChange}
                handleAlterationChange={handleAlterationChange}
                handlePriceChange={handlePriceChange}
                handleUrgentDateChange={handleUrgentDateChange}
                handleOnDeleteRowClick={handleOnDeleteRowClick}
                handleOnRefundRowClick={handleOnRefundRowClick}
                errMsg={errMsg}
                isDisabled={getRefundedState() || getDeletedState() || getFullyPaidState() || getHasItemsInPendingRefund()}
                >
              </SalesItems>
            </Tab>
            <Tab eventKey="measurement" title={<strong>Measurement</strong>}>
              <CustomerMeasurement
                measurements={measurements}
                handleMeasurementChange={handleMeasurementChange}
                handleMeasurementRemarksChange={handleMeasurementRemarksChange}
                isDisabled={ (getRefundedState() || getDeletedState() || getFullyPaidState()) && !getHasItemsInActiveFitGtd() }
                isFemale={custDetails && custDetails.gender && custDetails.gender.toLowerCase() === 'female'}
                handleStepByStepMeasurementChange={handleStepByStepMeasurementChange}
              ></CustomerMeasurement>
               <BodyPosture
                measurements={measurements}
                handleOptionChange={handlePostureChange}
              />
            </Tab>
            <Tab eventKey="garmentAllowance" title={<strong>Allowance</strong>}>
              <GarmentAllowanceTab 
                salesItems={salesItems}
                measurements={measurements}
                fitPreferences={fitPreferences}
                custAge={custAge}
                selectedItemIndex={selectedItemIndex}
                handleDesignOptionChange={handleDesignOptionChange}
                handleDuplicateDesign={handleDuplicateDesign}
                handleAllowanceChange={handleAllowanceChange}
                handleDuplicateAllowance={handleDuplicateAllowance}
                handleFitPreferenceChange={handleFitPreferenceChange}
                handleItemsTabClick={handleItemsTabClick}
                addAllowance={addAllowance}
                reduceAllowance={reduceAllowance}
              >
              </GarmentAllowanceTab>
            </Tab>
            <Tab eventKey="design" title={<strong>
                  <span className={`oi ${overalDesignError ? 'oi-warning text-warning' : ''}`}></span>
                  Design
                  </strong>}>
              <DesignTab 
                salesItems={salesItems}
                designSections={designSections}
                selectedItemIndex={selectedItemIndex}
                handleDesignOptionChange={handleDesignOptionChange}
                handleDesignRemarksChange={handleDesignRemarksChange}
                handleDuplicateDesign={handleDuplicateDesign}
                handleOnDownloadClick={handleOnDownloadClick}
                handleItemsTabClick={handleItemsTabClick}
                handleClearDesignClick={handleClearDesignClick}
              >
              </DesignTab>
            </Tab>
            <Tab eventKey="summary" title={<strong>Summary</strong>}>
              <SalesSummary
                custDetails={custDetails}
                measurements={measurements}
                salesItems={salesItems}
                totalAmount={totalAmount}
                consultants={consultants}
                orderGroupings={orderGroupings}
                selectedConsultant={selectedConsultant}
                handleOnSaveOrderClick={handleOnSaveOrderClick}
                handleOnConsultantChange={handleOnConsultantChange}
                handleOnPrintReceiptClick={handleOnPrintReceiptClick}
                handleOnSendReceiptClick={handleOnSendReceiptClick}
                handleOnCompleteOrderClick={handleOnCompleteOrderClick}
                handleOnDownloadAllOrdersClick={handleOnDownloadAllOrdersClick}
                isDisabled={getRefundedState() || getDeletedState() || getFullyPaidState()} 
                hasItemTypeChanged={itemTypeChanged} 
                handleOrderGroupingsChange={handleOrderGroupingsChange}
              >
                <SalesItems
                  salesItems={salesItems}
                  measurements={measurements}
                  totalAmount={totalAmount}
                  inSummary={true}
                  handleAddLine={handleAddLine}
                  handleSetTypeChange={handleSetTypeChange}
                  handleItemTypeChange={handleItemTypeChange}
                  handleFabricChange={handleFabricChange}
                  handlePriceChange={handlePriceChange}
                  handleUrgentDateChange={handleUrgentDateChange}
                  handleOnDeleteRowClick={handleOnDeleteRowClick}
                  handleOnRefundRowClick={handleOnRefundRowClick}
                  errMsg={errMsg}
                  isDisabled={getRefundedState() || getDeletedState() || getFullyPaidState() || getHasItemsInPendingRefund()}
                  orderHasDisbursedRPoints = {orderHasDisbursedRPoints}
                  isReferralActive={isReferralActive}
                  orderTimeAfterReferralFeature= {orderTimeAfterReferralFeature}
                  >
                </SalesItems>
              </SalesSummary>
              
            </Tab>
          </Tabs>
          {pagination ? 
            <GenericPagination 
              pagination={pagination} 
              rowLimit={rowLimit}
              handleOnPaginationClick={handleOnPaginationClick}
            ></GenericPagination> : <></>}
          <div className='mt-3 mb-3' style={{display: 'flex', justifyContent: 'flex-start'}}>
            {key !== 'details' && 
              <Button onClick={handleOnBackClick} variant="outline-secondary"><span className="oi oi-chevron-left"></span>Back</Button>
            }
            {key !== 'summary' && 
              <Button style={{marginLeft:'auto'}} onClick={handleOnContinueClick} variant="outline-secondary">
                Continue&nbsp;<span className="oi oi-chevron-right"></span>
              </Button>
            }
          </div>
        </Container>
        <StandardModal 
          size="lg"
          onConfirm={printDiv}
          onClose={handleReceipModalClose} 
          show={showReceiptModal}
          title="Receipt Preview"
          body={``}
        >
          <Receipt 
            custDetails={custDetails}
            measurements={measurements}
            salesItems={salesItems}
            totalAmount={totalAmount}
          ></Receipt>
        </StandardModal>
        <StandardModal 
          onConfirm={handleSendReceiptConfirm}
          onClose={handleSendReceiptClose} 
          onSecondaryConfirm={handleReceiptSecondaryConfirm}
          primaryConfirmButtonText={`Send Receipt To Customer`}
          secondaryConfirmButtonText={measurements && measurements.groupingId ?`Send Receipt To Group's Main Contact`:''}
          show={showSendReceiptModal}
          title="Send Receipt"
          size="lg"
          body={``}
        >
          {measurements && measurements.groupingId ? 
            `This order belongs to a group order. Please select action` :
            `Are you sure you want to send a receipt for this order?`
          }
        </StandardModal>
        <StandardModal 
          onConfirm={handleCompleteOrderConfirm}
          onClose={handleCompleteOrderClose} 
          show={showCompleteOrderModal}
          title="Complete Order"
          body={``}
        >
         Are you sure you want to complete this order? You will be redirected to order history page upon successful closing of order
        </StandardModal>

        <StandardModal 
          onConfirm={handleRefundItemConfirm}
          onClose={handleRefundItemClose} 
          show={showRefundItemModal}
          title="Refund Item"
          body={``}
        >

         <div>Are you sure you want to refund this item? Kindly state the reason</div>
          <Alert variant="danger" dismissible show={showRemarksAlert}>{remarksAlertMsg}</Alert>
          <Form.Group className="mt-3">
            <Form.Control required as="textarea" rows="3" onChange={handleOnRefundRemarksChange} value={refundRemarks} placeholder="Refund reason">
            </Form.Control>
          </Form.Group>
        </StandardModal>
        <StandardModal 
          onConfirm={handleCopyDesignConfirm}
          onClose={handleCopyDesignClose} 
          show={showCopyDesignModal}
          title="Copy Design"
          body={``}
        >
         Are you sure you want to copy design from <strong>{getSalesItemById(designSourceTarget.sourceTempId) ? getSalesItemById(designSourceTarget.sourceTempId).item_type : ''} - {getSalesItemById(designSourceTarget.sourceTempId) ? getSalesItemById(designSourceTarget.sourceTempId).item_desc : ''}</strong>?
        </StandardModal>
        <StandardModal 
          onConfirm={handleCopyAllowanceConfirm}
          onClose={handleCopyAllowanceClose} 
          show={showCopyAllowanceModal}
          title="Copy Allowance"
          body={``}
        >
         Are you sure you want to copy allowance from <strong>{getSalesItemById(designSourceTarget.sourceTempId) ? getSalesItemById(designSourceTarget.sourceTempId).item_type : ''} - {getSalesItemById(designSourceTarget.sourceTempId) ? getSalesItemById(designSourceTarget.sourceTempId).item_desc : ''}</strong>?
        </StandardModal>
        {/* {(<div><pre>{JSON.stringify(custDetails, null, 2) }</pre></div>)} */}
        {/* {(<div><pre>{JSON.stringify(measurements, null, 2) }</pre></div>)} */}
        {/* {(<div><pre>{JSON.stringify(totalAmount, null, 2) }</pre></div>)} */}
        {/* {(<div><pre>{JSON.stringify(salesItems, null, 2) }</pre></div>)} */}
        {/* {(<div><pre>{JSON.stringify(designSections, null, 2) }</pre></div>)} */}
    </Layout>
  )
}

export default SalesOrder