import React, {useContext} from 'react';
import {Tabs, Badge, Row, Form, Col, Button} from 'react-bootstrap'

import garmentAllowance from '../../helpers/garmentAllowance';
import { isProcessStarted } from '../../helpers/statusHelpers'
import constants from '../../helpers/constants';
import {isShirts, isTrousers, isJackets, isVests} from '../../helpers/itemTypeHelpers'
// import { CustContext } from '../../pages/orders/salesOrder'
// import { DecodedTokenContext } from '../../components/layout'

const AllowanceItem = ({item, measurements, custAge, handleAllowanceChange, salesItems, handleDuplicateAllowance, fitPreferences, handleFitPreferenceChange, addAllowance, reduceAllowance}) => {

  // const cust = useContext(CustContext);
  // const token = useContext(DecodedTokenContext)
  
  const getTotalMeasurement = (bodySection, option, defaultPrefValue=0, unit) => {
    const allowanceObj = item['allowance'] ? JSON.parse(item['allowance']) : {};
    let allowanceValue = allowanceObj[option] ? Number(allowanceObj[option][unit]) : 0;
    let actualValue = 0;
    switch (item.item_type) {
      case 'SP':
      case 'PRIMO SP':
        if (option === 'knee') {
          // For SP's knee, it is dependent on thigh measurements 
          const thighActualValue = measurements[bodySection] && measurements[bodySection]['thigh'] && measurements[bodySection]['thigh'][unit] ? Number(measurements[bodySection]['thigh'][unit]) : 0;
          // const thighAllowanceValue = allowanceObj['thigh'][unit];
          actualValue = Number(thighActualValue)  
        } else {
          actualValue = measurements[bodySection] && measurements[bodySection][option] && measurements[bodySection][option][unit] ? Number(measurements[bodySection][option][unit]) : 0;  
        }
        break;
      case 'LS':
      case 'PRIMO LS':
        actualValue = measurements[bodySection] && measurements[bodySection][option] && measurements[bodySection][option][unit] ? Number(measurements[bodySection][option][unit]) : 0;  
        if (option.indexOf('Wrist') !== -1) {
          allowanceValue = allowanceObj[`${defaultPrefValue ? defaultPrefValue : option}`] ? Number(allowanceObj[`${defaultPrefValue ? defaultPrefValue : option}`][unit]) : 0;
        }
        break;
      case 'SBJ':
      case 'PRIMO SBJ':
      case 'DBJ':
      case 'PRIMO DBJ':
        actualValue = measurements[bodySection] && measurements[bodySection][option] && measurements[bodySection][option][unit] ? Number(measurements[bodySection][option][unit]) : 0;  
        if (option.indexOf('Wrist') !== -1) {
          allowanceValue = allowanceObj[`${defaultPrefValue ? defaultPrefValue : option}`] ? Number(allowanceObj[`${defaultPrefValue ? defaultPrefValue : option}`][unit]) : 0;
        }
        break;
      default:
        actualValue = measurements[bodySection] && measurements[bodySection][option] && measurements[bodySection][option][unit] ? Number(measurements[bodySection][option][unit]) : 0;
        break;
    }
    return Number(allowanceValue) + Number(actualValue);
  }

  const getOptionValue = (element, type) => {
    const allowanceObj = item.allowance ? JSON.parse(item.allowance) : {};
    const entity = element.defaultPrefValue ? element.defaultPrefValue : element.entity;
    if (allowanceObj[entity]) {
      return allowanceObj[entity][type]
    }
    // const firstOption = garmentAllowance[item.item_type]['sections'][element]['value'] ? garmentAllowance[item.item_type]['sections'][element]['value'][0] : garmentAllowance[item.item_type]['sections'][element][0]

    const firstOption = ''

    if (type === 'inch') {
      return firstOption; 
    }

    if (type === 'cm') {
      return Number(firstOption) * 2.54; 
    }
  }

  const getFitPreferenceValue = () => {
    if (!item.allowance) return "";
    const allowanceObj = JSON.parse(item.allowance)
    if (!allowanceObj.itemFitPreference) return "";
    return JSON.parse(item.allowance).itemFitPreference;
  }

  const isHideTruthy = (garmentAllowance, index) => {
    if (garmentAllowance[item.item_type]['sections'][index]['hideWhenDesign']) {
      const hideCondition = garmentAllowance[item.item_type]['sections'][index]['hideWhenDesign'];
      let propertyName = Object.keys(hideCondition)[0];
      let value = '';
      value = hideCondition[propertyName];
      const designObj = item.design ? JSON.parse(item.design) : {};
      if (designObj && designObj[propertyName] ) {
        if (value && Array.isArray(value)) {
          return value.some(function (currentValue) {
            return designObj[propertyName]['optionIdentifier'] === currentValue || designObj[propertyName]['optionIdentifier'].indexOf(currentValue) !== -1
          })
        }
      }
      return false;
    }
  }

  const handleOnClick = (e) => {
    const dataTempId = e.target.dataset.tempId;
    const element = document.querySelector(`#allowanceSelect${dataTempId}`);
    const sourceTempId = element.value;
    const targetTempId = item.tempId ? item.tempId : item.id;
    handleDuplicateAllowance({sourceTempId, targetTempId})
  }

  const isSameTypeOrPrimoVariant = (salesItem, item) => {
    if(salesItem.itemType) {
      return salesItem.itemType === item.itemType || 
      salesItem.itemType === `PRIMO ${item.itemType}` || 
      `PRIMO ${salesItem.itemType}` === item.itemType
    }

    return salesItem.item_type === item.item_type || 
      salesItem.item_type === `PRIMO ${item.item_type}` || 
      `PRIMO ${salesItem.item_type}` === item.item_type
  }

  const cloneAllowanceDropdown = () => {
    // if (isProcessStarted(item)) return;
    let optionArray = [];
    let atLeastHaveOneOption = false;
    salesItems.forEach((salesItem, index) => {
      if (salesItem.tempId) {
      if(salesItem.tempId === item.tempId ) return;
      } else {
        if(salesItem.id === item.id ) return;
      }
      if(isSameTypeOrPrimoVariant(salesItem, item)) {
          atLeastHaveOneOption = true;
          optionArray.push(<option key={index} value={`${salesItem.tempId ? salesItem.tempId : salesItem.id}`}>{salesItem.item_type} - {salesItem.item_desc}</option>)
      }
    })

    if (atLeastHaveOneOption) {
      return (
        <Form.Group as={Row} className="mt-3">
          <Form.Label column sm={3}>
            Copy Allowance From
          </Form.Label>
          <Col sm={7}>
            <Form.Control 
              as="select" 
              placeholder="Please enter value"
              id={`allowanceSelect${item.tempId ? item.tempId : item.id}`}
            >
              {optionArray.map(option => {
                return (
                  option
                )
              })}
            </Form.Control>
          </Col>
          <Col sm={2}>
            <Button data-temp-id={item.tempId ? item.tempId : item.id} onClick={handleOnClick}>Copy Allowance</Button>
          </Col>    
        </Form.Group>
      )
    }
  }

  const fitTypeRecommendation = (params) => {
    if (custAge > 90) return;
    return (
      <Row className="mt-3">
        <Col sm={3}>
          Fit Advisory
        </Col>
        <Col sm={9}>
          {!custAge || custAge > 90 ? (
            <>
            Please update client's date of birth to receive advisory
            </>
          ) : (
            <>
            Based on client's age ({custAge}), the client may prefer <u>{fitTypeByItemTypeAndAge(item.item_type, custAge)}</u> fit.
            </>
          )}
        </Col>
      </Row>
    )
  }

  const fitTypeByItemTypeAndAge = (itemType, custAge) => {
    switch (itemType) {
      case 'SP':
      case 'LP':
      case 'PRIMO SP':
      case 'PRIMO LP':
        if (Number(custAge) <= 40) return `SLIM`;
        if (Number(custAge) > 40 && Number(custAge) <= 50) return `REGULAR`;
        if (Number(custAge) > 50) return `COMFORT`;
        break;
      default:
        if (Number(custAge) <= 40) return `SLIM`;
        if (Number(custAge) > 40 && Number(custAge) <= 50) return `REGULAR`;
        if (Number(custAge) > 50) return `COMFORT`;
        break;
    }
  }

  const itemHasFitPreference = (item) => {
    if (item.allowance && JSON.parse(item.allowance) && JSON.parse(item.allowance).itemFitPreference) {
      return true
    }
    return false;
  }

  const fitPreferenceDropDown = () => {
    return (
      <>
        <Row className="mt-3">
          <Col>
          <Form.Group as={Row} key={`fitPreference-${item.item_type}${item.item_desc}`}>
              <Form.Label column sm={3}>Fit Preference</Form.Label>
              <Col sm={7}>
                <Form.Control 
                  data-item-id={!item.id ? item.tempId : item.id}
                  as="select"
                  value={ getFitPreferenceValue()}
                  onChange={handleFitPreferenceChange}
                  disabled={isProcessStarted(item) && itemHasFitPreference(item)}
                >
                  <option value="" >Select Fit Preference {item.item_type}</option>
                  {getFitPreferenceOptions()}
                </Form.Control>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
          </Col>
          <Col sm={9}>{FitPreferenceDescription()}
          </Col>
        </Row>
      </>
    )
  }

  const getFitPreferenceOptions = () => {
    const option = fitPreferences.filter(opt => opt.itemType === item.item_type);
    let pleatNumber = 'No Pleat'

    if (isTrousers(item.item_type)) {
      if (item.design) {
        const designObj = JSON.parse(item.design);
        if (designObj.pleats && designObj.pleats.optionIdentifier === 'none') {
          pleatNumber = 'No Pleat'
        }
        if (designObj.pleats && designObj.pleats.optionIdentifier === 'single') {
          pleatNumber = '1 Pleat'
        }
        if (designObj.pleats && designObj.pleats.optionIdentifier === 'double') {
          pleatNumber = '2 Pleat'
        }
      }
    }

    if (!option[0]) return ;
    if (!option[0].data) return ;
    const set = new Set;
    
    if (isTrousers(item.item_type)) {
      option[0].data.forEach(element => {
        if (element.fitType === `Slim ${pleatNumber}` || element.fitType === `Regular ${pleatNumber}` || element.fitType === `Comfort ${pleatNumber}`)
          set.add(element.fitType);
      })
    } else {
      set.add('Slim')
      set.add('Regular')
      set.add('Comfort')
    }
    return Array.from(set).map( (element, index) => {
      return <option key={index} value={element}>{element}</option>
    })
  }

  const FitPreferenceDescription = () => {
    const selectedFitPreference = getFitPreferenceValue();
    if (selectedFitPreference.indexOf('Slim') !== -1)  return `${constants.SLIM_FIT_ADVISORY_TEXT}`;
    if (selectedFitPreference.indexOf('Regular') !== -1)  return `${constants.REGULAR_FIT_ADVISORY_TEXT}`;
    if (selectedFitPreference.indexOf('Comfort') !== -1)  return `${constants.COMFORT_FIT_ADVISORY_TEXT}`;
  }

  const getMeasurementPointValue = (element, unit) => {
    if (item && item.allowance) {
      const entity = element.defaultPrefValue ? element.defaultPrefValue : element.entity;
      const allowanceObj = JSON.parse(item.allowance);
      if (allowanceObj[entity] && allowanceObj[entity][unit]) {
        return allowanceObj[entity][unit];
      }
    }
    return 0;
  }

  const statusNote = () => {
    // if (isProcessStarted(item)) {
      // return (
      //   <div className="mb-3">
      //     <Badge pill variant="danger" >Item in Production. Allowance changes are not allowed</Badge>
      //   </div>
      // )
    // }
  }

  const isFitPreferenceSelected = () => {
    if (item.allowance) {
      const allowanceObj = JSON.parse(item.allowance);
      if (!allowanceObj) return false;

      const itemFitPreference = allowanceObj.itemFitPreference;
      if (!itemFitPreference) return false;
      return true
    }
    return false;
  }

  const headers = () => {
  return (
    <Row className="my-3">
      <Col sm={3}></Col>
      <Col sm={2}><h6>Actual+Allowance</h6></Col>
      <Col sm={2}><h6>Actual+Allowance (Cm)</h6></Col>
      <Col sm={3}>
        <Row>
          <Col sm={2}>
          </Col>
          <Col sm={8}>
            <h6>Allowance (down or up to 2 inch)</h6>
          </Col>
          <Col sm={2}>
          </Col>
        </Row>
      </Col>
      <Col sm={2}><h6>Centimetres</h6></Col>
    </Row>
    )
  }

  const getAllowancePointsInputFields = (params) => {
    return (
      garmentAllowance[item.item_type] && garmentAllowance[item.item_type]['sections'].map((element, index) => {
        if (isHideTruthy(garmentAllowance, index)) {
          // should hide this option
          return;
        }
        if (elementShouldSkip(item.item_type, element.bodySection, element, 'inch')) return;

        return (
          <>
          <Form.Group as={Row} key={`allowance-${item.item_type}${item.item_desc}${index}`}>
            <Form.Label column sm={3}>
              {element.label}<br />
              <small>{element.info ? element['info'] : ''}</small>
            </Form.Label>
            <Col sm={2}>
              <Form.Control 
                value={getTotalMeasurement(element.bodySection, element.entity, element.defaultPrefValue, 'inch').toFixed(2)} 
                disabled={true}
              />
            </Col>
            <Col sm={2}>
              <Form.Control 
                value={getTotalMeasurement(element.bodySection, element.entity, element.defaultPrefValue, 'cm').toFixed(2)} 
                disabled={true}
              />
            </Col>
            <Col sm={3}>
              <Row>
                <Col sm={2}>
                  <Button 
                    variant="dark" 
                    data-allowance-point={element.defaultPrefValue ? element.defaultPrefValue : element.entity} 
                    data-id={!item.id ? item.tempId : item.id} 
                    onClick={reduceAllowance} 
                    disabled={false}
                  >-</Button>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    value={getMeasurementPointValue(element, 'inch')}
                    disabled={true}
                  >
                  </Form.Control>
                </Col>
                <Col sm={2} style={{paddingLeft: '0px'}}>
                  <Button 
                    variant="dark" 
                    data-allowance-point={element.defaultPrefValue ? element.defaultPrefValue : element.entity} 
                    data-id={!item.id ? item.tempId : item.id}
                    onClick={addAllowance}
                    disabled={false}
                  >+</Button> 
                </Col>
              </Row>
            </Col>
            <Col sm={2}>
              <Form.Control 
                data-unit="cm"
                value={getOptionValue(element, 'cm')} 
                onChange={handleAllowanceChange}
                disabled={true}
              />
            </Col>
          </Form.Group>
          </>
        )
      })
    )
  }

  const elementShouldSkip = (itemType, bodySection, element, unit) => {
    switch (itemType) {
      case 'SP':
      case 'PRIMO SP':
        break;
      default:
        if (element.entity === 'knee') {
          const kneeActualValue = measurements[bodySection] && measurements[bodySection][element.entity] && measurements[bodySection][element.entity][unit] ? Number(measurements[bodySection][element.entity][unit]) : 0;
          const calfActualValue = measurements[bodySection] && measurements[bodySection]['calf'] && measurements[bodySection]['calf'][unit] ? Number(measurements[bodySection]['calf'][unit]) : 0;
          if (kneeActualValue < calfActualValue) return true;
        }
        if (element.entity === 'calf') {
          const kneeActualValue = measurements[bodySection] && measurements[bodySection]['knee'] && measurements[bodySection]['knee'][unit] ? Number(measurements[bodySection]['knee'][unit]) : 0;
          const calfActualValue = measurements[bodySection] && measurements[bodySection][element.entity] && measurements[bodySection][element.entity][unit] ? Number(measurements[bodySection][element.entity][unit]) : 0;
          if (calfActualValue <= kneeActualValue) return true;
        }
        break;
    }

    return false;
  }

  return (
    <>
    {item.alteration ? <>
        <p>Item is for alteration</p>
      </> : 
      <>
        {statusNote()}
        {cloneAllowanceDropdown()}
        {fitTypeRecommendation()}
        {fitPreferenceDropDown()}
        {isFitPreferenceSelected() ? headers() : (<></>)}
        {
          isFitPreferenceSelected() ? getAllowancePointsInputFields() : (<> </>)
        }
      </>
    }
    {/* {(<div><pre>{JSON.stringify(token, null, 2) }</pre></div>)} */}
    {/* {(<div><pre>{JSON.stringify(JSON.parse(item.allowance), null, 2) }</pre></div>)} */}
    {/* {(<div><pre>{JSON.stringify(item, null, 2) }</pre></div>)} */}
    {/* {(<div><pre>{JSON.stringify(fitPreferences, null, 2) }</pre></div>)} */}
    </>
  )
}

export default AllowanceItem