import React, {useState, useEffect} from 'react';
import {Form, Col, Row, ListGroup, Button, Badge, DropdownButton, ButtonGroup, Dropdown, Tab} from 'react-bootstrap'
import { isProcessStarted } from '../../helpers/statusHelpers';
import DesignSidebar from '../designSidebar';
import styles from './item.module.css';

const ItemDesign = ({item, sections, handleDesignOptionChange, handleDesignRemarksChange, handleDuplicateDesign, handleOnDownloadClick, handleClearDesignClick, salesItems}) => {
  const [indexShowing, setIndexShowing] = useState(0)
  const isOptionActive = (sectionIdentifier, optionIdentifier) => {
    if (item && item.design) {
      const designObj = JSON.parse(item.design)
      if (designObj && designObj[sectionIdentifier]) {
        return designObj[sectionIdentifier]['optionIdentifier'] === optionIdentifier;
      }
      return false;
    }
  }
  
  const handleOnClick = (e) => {
    const dataTempId = e.target.dataset.tempId;
    const element = document.querySelector(`#designSelect${dataTempId}`);
    const sourceTempId = element.value;
    const targetTempId = item.tempId ? item.tempId : item.id;
    handleDuplicateDesign({sourceTempId, targetTempId})
  }

  const isSameTypeOrPrimoVariant = (salesItem, item) => {
    return salesItem.item_type === item.item_type || 
      salesItem.item_type === `PRIMO ${item.item_type}` || 
      `PRIMO ${salesItem.item_type}` === item.item_type
  }

  const cloneDesignDropdown = () => {
    if (isProcessStarted(item)) return;
    let optionArray = [];
    let atLeastHaveOneOption = false;
    salesItems.forEach((salesItem, index) => {
      if (salesItem.tempId) {
      if(salesItem.tempId === item.tempId ) return;
      } else {
        if(salesItem.id === item.id ) return;
      }
      if(isSameTypeOrPrimoVariant(salesItem, item)) {
          atLeastHaveOneOption = true;
          optionArray.push(<option key={index} value={`${salesItem.tempId ? salesItem.tempId : salesItem.id}`}>{salesItem.item_type} - {salesItem.item_desc}</option>)
      }
    })

    if (atLeastHaveOneOption) {
      return (
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Copy Design From
          </Form.Label>
          <Col sm={8}>
            <Form.Control 
              as="select" 
              placeholder="Please enter value"
              id={`designSelect${item.tempId ? item.tempId : item.id}`}
            >
              {optionArray.map(option => {
                return (
                  option
                )
              })}
            </Form.Control>
          </Col>
          <Col sm={2}>
            <Button data-temp-id={item.tempId ? item.tempId : item.id} onClick={handleOnClick}> Copy Design</Button>
          </Col>    
        </Form.Group>
      )
    }
  }

  const statusNote = () => {
    if (isProcessStarted(item)) {
      return (
        <div className="mb-3">
          <Badge pill variant="danger" >Item in Production. Design changes are not allowed</Badge>
        </div>
      )
    }
  }

  const handleSectionDescClick = (e) => {
    const {showIndex} = e.target.dataset;
    if (Number(indexShowing) === Number(showIndex)) {
      setIndexShowing(-1);
      return;
    }
    setIndexShowing(Number(showIndex))    
  }

  const isOptionSelectedForSection = (section) => {
    const {design} = salesItems.find(saleItem => {
      if (saleItem.tempId) {
        return saleItem.tempId === item.tempId  
      }
      return saleItem.id === item.id
    })
    if (design) {
      const designObj = JSON.parse(design);
      if (designObj[section.sectionIdentifier] && Object.keys(designObj[section.sectionIdentifier]).length > 0) {
        return true;
      }
    }
    return false;
  }

  const downloadDesignButton = () => {
    return (
      <Row className="mt-3">
        <Col>
          <DropdownButton as={ButtonGroup} variant="info" title="Download Design Form" id="bg-nested-dropdown">
            <Dropdown.Item eventKey="1"
              data-blank={false}
              data-kutesmart={false}
              data-garment-measurement={false}
              data-measurement-numbers={true}
              data-order-id={item.orderId || item.order_id} 
              data-item-id={item.id} 
              data-item-type={item.item_type} 
              data-item-desc={item.item_desc} 
              onClick={handleOnDownloadClick}
              disabled={item.designSheetDownloadable && item.design ? false : true}
            >
             With Measurements
            </Dropdown.Item>
            <Dropdown.Item eventKey="4"
              data-blank={false}
              data-kutesmart={true}
              data-garment-measurement={false}
              data-measurement-numbers={true}
              data-order-id={item.orderId || item.order_id} 
              data-item-id={item.id} 
              data-item-type={item.item_type} 
              data-item-desc={item.item_desc} 
              onClick={handleOnDownloadClick}
              disabled={item.designSheetDownloadable && item.design ? false : true}
            >
              For Kutesmart
            </Dropdown.Item>
            <Dropdown.Item eventKey="2"
              data-blank={false}
              data-kutesmart={false}
              data-garment-measurement={true}
              data-measurement-numbers={true}
              data-order-id={item.orderId || item.order_id} 
              data-item-id={item.id} 
              data-item-type={item.item_type} 
              data-item-desc={item.item_desc} 
              onClick={handleOnDownloadClick}
              disabled={item.designSheetDownloadable && item.design ? false : true}
            >
              With Garment Measurement
            </Dropdown.Item>
            {/* <Dropdown.Item eventKey="2"
              data-blank={true}
              data-measurement-numbers={false}
              data-order-id={item.orderId || item.order_id} 
              data-item-id={item.id} 
              data-item-type={item.item_type} 
              data-item-desc={item.item_desc} 
              onClick={handleOnDownloadClick}
              disabled={item.designSheetDownloadable && item.design ? false : true}
            >
            Without Measurements
            </Dropdown.Item>
            <Dropdown.Item eventKey="3"
              data-blank={false}
              data-measurement-numbers={false}
              data-order-id={item.orderId || item.order_id} 
              data-item-id={item.id} 
              data-item-type={item.item_type} 
              data-item-desc={item.item_desc} 
              onClick={handleOnDownloadClick}
              disabled={item.designSheetDownloadable && item.design ? false : true}
            >
            Without Measurement Numbers (Design only)
            </Dropdown.Item> */}
          </DropdownButton>
          {/* <Button 
            variant="info"
            data-order-id={item.orderId || item.order_id} 
            data-item-id={item.id} 
            data-item-type={item.item_type} 
            data-item-desc={item.item_desc} 
            onClick={handleOnDownloadClick}
            disabled={item.designSheetDownloadable && item.design ? false : true}
          >
            <span class="oi oi-data-transfer-download"></span> Download Design Form
          </Button> */}
          {!item.designSheetDownloadable && (
            <span className='text-danger'> *Please save order first to download</span>
          )}
          {!item.design && (
            <span className='text-danger'> *Please choose some designs</span>
          )}
        </Col>
      </Row>
    )
    
  }

  const itemDesignRemarks = () => {
    return (
      <Row>
        <Col>
          <Form
            className={styles.designRemarks}
            data-item-id={item.tempId || item.id}
            as="textarea"
            placeholder="Design Remarks"
            value={item.design && JSON.parse(item.design) && JSON.parse(item.design).remarks ? JSON.parse(item.design).remarks : ''}
            onChange={handleDesignRemarksChange}
          >
          </Form>
        </Col>
      </Row>
    )
  }
  
  return (
    <div className="mt-3">
      {item.alteration ? <>
        {downloadDesignButton()}
      </> : 
      <>
        {cloneDesignDropdown()}
        {statusNote()}
        <DesignSidebar item={item} handleClearDesignClick={handleClearDesignClick}/>
        <Row className="mb-3">
          <Col>  
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#pantsWaistHeight" >
              <Row>
                <Col sm={5}>
                  <ListGroup>
                    {sections && Array.isArray(sections.sections) && sections.sections.length > 0 ?
                      (
                        sections.sections.map((section, index) => {
                          const design = item.design ? JSON.parse(item.design) : '';
                          const optionSelected = design[section.sectionIdentifier] && design[section.sectionIdentifier]['optionIdentifier'] ? design[section.sectionIdentifier]['optionIdentifier'] : '';
                          const sectionFound = sections.sections.find((item) => {
                            return item.sectionIdentifier === section.sectionIdentifier;
                          })
                          const option = sectionFound.options.find(opt => {
                            return opt.optionIdentifier === optionSelected
                          });
                          return <ListGroup.Item action href={`#${section.sectionIdentifier}`} key={index}>
                            {section.sectionDesc} {option ? (<span className="font-italic text-muted"><small><u>{option.optionDesc}</u> +${option.optionPrice}</small></span>) : ''}
                          </ListGroup.Item>
                        })
                      )
                      : <></>
                    }
                  </ListGroup>
                </Col>
                <Col sm={7}>
                  <Tab.Content>
                    {sections && Array.isArray(sections.sections) && sections.sections.length > 0 ?
                      (
                        sections.sections.map((section, index) => {
                          return (
                            section.options && Array.isArray(section.options) ?
                              (
                                <Tab.Pane eventKey={`#${section.sectionIdentifier}`} key={index}>
                                  <Row>
                                  {
                                    section.options.sort((a,b) => a.sequence - b.sequence ).map((option, index2) => {
                                      return (
                                        <Col sm={3} key={index2}>
                                          <Button
                                            variant={isOptionActive(section.sectionIdentifier, option.optionIdentifier) ? 'primary' : 'secondary'}
                                            custom="true"
                                            checked={isOptionActive(section.sectionIdentifier, option.optionIdentifier)}
                                            data-item-id={item.id ? item.id : item.tempId}
                                            data-option-price={option.optionPrice} 
                                            data-option-desc={option.optionDesc} 
                                            data-option-identifier={option.optionIdentifier} 
                                            data-option-kutesmart-code={option.kutesmartCode}
                                            data-section-identifier={section.sectionIdentifier} 
                                            data-section-desc={section.sectionDesc} 
                                            data-section-for-frontview={section.forFrontview}
                                            data-section-for-backview={section.forBackview}
                                            data-overlay-image-url={option.overlayImageUrl}
                                            onClick={handleDesignOptionChange}
                                            type={'radio'}
                                            id={`${section.sectionIdentifier}-${index}`}
                                            name={`${section.sectionIdentifier}`}
                                            label={`${option.optionDesc} (+${option.optionPrice})`}
                                            disabled={isProcessStarted(item)}
                                            
                                            // checked={true}
                                            className={`pointer mr-3 mb-3`}
                                            
                                          >
                                            {`${option.optionDesc} (+${option.optionPrice})`}
                                          </Button>
                                        </Col>
                                      )
                                    })
                                  }
                                  </Row>
                                </Tab.Pane> 
                              )
                            : <></>                      
                          )
                        }) // for sections.map closing
                      )
                      : <></>
                    }
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
        {itemDesignRemarks()}
        {downloadDesignButton()}
      </>
      }
      {/* {downloadDesignButton()} */}
      {/* {(<div><pre>{JSON.stringify(measurements, null, 2) }</pre></div>)} */}
      {/* <div><pre>{JSON.stringify(item, null, 2) }</pre></div> */}
      {/* <div><pre>{JSON.stringify(item && item.design ? JSON.parse(item.design):'', null, 2) }</pre></div> */}
      {/* <div><pre>{JSON.stringify(sections, null, 2) }</pre></div> */}

    </div>
  )
}

export default ItemDesign 