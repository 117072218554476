import React, { useState } from 'react';
import {Tabs, Tab} from 'react-bootstrap'
import AllowanceItem from '../components/allowance/allowanceItem'
import { isProcessStarted } from '../helpers/statusHelpers'

const GarmentAllowanceTab = ({salesItems, measurements, fitPreferences, custAge, selectedItemIndex, handleAllowanceChange, handleDuplicateAllowance, handleFitPreferenceChange, handleItemsTabClick, addAllowance, reduceAllowance}) => {

  const isAllItemTypeEmpty = (items) => {
    let isAllItemTypeEmpty = true;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.item_type) {
        isAllItemTypeEmpty = false;
        break;
      }
    }
    return isAllItemTypeEmpty;
  }

  const itemHasFitPreference = (item) => {
    if (item.allowance && JSON.parse(item.allowance) && JSON.parse(item.allowance).itemFitPreference) {
      return true
    }
    return false;
  }

  return (
    <>
      {salesItems && Array.isArray(salesItems) && salesItems.length > 0 && !isAllItemTypeEmpty(salesItems) ? 
        <Tabs onSelect={handleItemsTabClick} activeKey={selectedItemIndex ? selectedItemIndex : salesItems[0].id ? salesItems[0].id : salesItems[0].tempId}>
        { 
          salesItems && (
            salesItems.map((lineItem, index) => {
              if (lineItem.item_type === 'OTHERS' || lineItem.item_type === 'BESPOKE' || lineItem.item_type === 'ACCESSORY' || lineItem.refund_status === 'approved') return;
              return (

                <Tab eventKey={`${lineItem.id ? lineItem.id : lineItem.tempId}`} key={index} title={<small>{lineItem.item_type}<br />{lineItem.item_desc.substring(0,15)}</small>}>
                  <h5 className="mt-3 d-inline-block">{lineItem.item_type} Allowance</h5>{isProcessStarted(lineItem) && itemHasFitPreference(lineItem) ? <span className="ml-3 text-danger"><span className="oi oi-warning"></span>Item already in production, take care when changing allowance<span className="oi oi-warning"></span></span> : <></>}
                  <AllowanceItem
                    item={lineItem}
                    measurements={measurements}
                    fitPreferences={fitPreferences}
                    custAge={custAge}
                    handleAllowanceChange={handleAllowanceChange}
                    handleDuplicateAllowance={handleDuplicateAllowance}
                    handleFitPreferenceChange={handleFitPreferenceChange}
                    salesItems={salesItems}
                    addAllowance={addAllowance}
                    reduceAllowance={reduceAllowance}
                  ></AllowanceItem>
                  
                </Tab>
            )}
          ))
        }
        </Tabs>
        : <>Please select an item type</>
      }
      {/* <div><pre>{JSON.stringify(totalAmount, null, 2) }</pre></div> */}
      {/* <div><pre>{JSON.stringify(salesItems, null, 2) }</pre></div> */}
      {/* <div><pre>{JSON.stringify(measurements, null, 2) }</pre></div> */}
      {/* <div><pre>{JSON.stringify(fitPreferences, null, 2) }</pre></div> */}

    </>
  )
}

export default GarmentAllowanceTab