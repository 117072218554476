import React from 'react';
import {Tabs, Tab} from 'react-bootstrap'
import ItemDesign from './design/item';

const DesignTab = ({salesItems, designSections, selectedItemIndex, handleDesignOptionChange, handleDesignRemarksChange, handleDuplicateDesign, handleOnDownloadClick, handleItemsTabClick, handleClearDesignClick}) => {
  const isAllItemTypeEmpty = (items) => {
    let isAllItemTypeEmpty = true;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.item_type) {
        isAllItemTypeEmpty = false;
        break;
      }
    }
    return isAllItemTypeEmpty;
  }
  

  return (
    <>
      {salesItems && Array.isArray(salesItems) && salesItems.length > 0 && !isAllItemTypeEmpty(salesItems) ? 
        <Tabs onSelect={handleItemsTabClick} activeKey={selectedItemIndex ? selectedItemIndex : salesItems[0].id ? salesItems[0].id : salesItems[0].tempId}>
        { 
          salesItems && (
            salesItems.map((lineItem, index) => {
              if (lineItem.item_type === 'OTHERS' || lineItem.item_type === 'BESPOKE' || lineItem.item_type === 'ACCESSORY' || lineItem.refund_status === 'approved') return;
              const sections = designSections.find((section) => {
                return section.itemType === lineItem.item_type;
              })
              return (
                <Tab eventKey={`${lineItem.id ? lineItem.id : lineItem.tempId}`} key={index} title={<small>
                  <span className={`oi ${lineItem.itemDesignError ? 'oi-warning text-warning' : ''}`}></span>
                  {lineItem.item_type}<br />{lineItem.item_desc.substring(0,15)}
                  </small>}>
                  <ItemDesign 
                    item={lineItem} 
                    sections={sections} 
                    handleDesignOptionChange={handleDesignOptionChange}
                    handleDesignRemarksChange={handleDesignRemarksChange}
                    handleDuplicateDesign={handleDuplicateDesign}
                    handleOnDownloadClick={handleOnDownloadClick}
                    salesItems={salesItems}
                    handleClearDesignClick={handleClearDesignClick}
                  >
                  </ItemDesign>
                </Tab>
            )}
          ))
        }
        </Tabs>
        : <>Please select an item type</>
      }
             
      {/* <div><pre>{JSON.stringify(totalAmount, null, 2) }</pre></div> */}
      {/* <div><pre>{JSON.stringify(salesItems, null, 2) }</pre></div> */}
      {/* <div><pre>{JSON.stringify(measurements, null, 2) }</pre></div> */}

    </>
  )
}

export default DesignTab