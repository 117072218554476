import React from 'react';
import {Col, Row} from 'react-bootstrap'
import {isJackets, isSBJ, isDBJ, isTrousers, isLongPants, isShortPants, isVests, isSBV, isDBV, isLongSleeveShirts, isShortSleeveShirts} from '../helpers/itemTypeHelpers'
import styles from './designStack.module.css'

const DesignStack = ({item, vertical}) => {
  const frontOverlayImages = () => {
    if (!item.design) return '';
    const optionArray = Object.values(JSON.parse(item.design));
    const overlays = optionArray.filter(item=> {
      if (item && item.forFrontview === 'true' && item.overlayImageUrl) return item.overlayImageUrl;
    }).map(item=> {
      return `url('${item.overlayImageUrl}')`
    });    

    if (isJackets(item.item_type)) {
      if(isSBJ(item.item_type)) {
        overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/MainJacketFront.png')`)
      }
      if(isDBJ(item.item_type)) {
        overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/MainDBJFront.png')`)
      }
      return  `${overlays.join(',')}`;
    } 
    
    if (isTrousers(item.item_type)) {
      if (isLongPants(item.item_type)) {
        overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/SS_initialPosition_none.png')`)
      }
      if (isShortPants(item.item_type)) {
        overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/SP+Main+Front.png')`)
      }
      return  `${overlays.join(',')}`;
    }

    if (isVests(item.item_type)) {
      if (isSBV(item.item_type)) {
        overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/MainFrontVest.png')`);
      }
      if (isDBV(item.item_type)) {
        overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/MainFrontDBV.png')`);
      }
      return  `${overlays.join(',')}`;
    }

    if (isLongSleeveShirts(item.item_type)) {
      overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/MainLSFront.png')`);
      return  `${overlays.join(',')}`;
    }

    if (isShortSleeveShirts(item.item_type)) {
      overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/MainSSFront.png')`);
      return  `${overlays.join(',')}`;
    }
    return  `${overlays.join(',')}`;
  }


  const backOverlayImages = () => {
    if (!item.design) return '';
    const optionArray = Object.values(JSON.parse(item.design));
    const overlays = optionArray.filter(item=> {
      if (item && item.forBackview === 'true' && item.overlayImageUrl) return item.overlayImageUrl;
    }).map(item=> {
      return `url('${item.overlayImageUrl}')`
    });

    if (isJackets(item.item_type)) {
      overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/MainJacketBack.png')`)
      return  `${overlays.join(',')}`;
    } 
    if (isTrousers(item.item_type)) {
      if (isLongPants(item.item_type)) {
        overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/MainBack.png')`)
      }
      if (isShortPants(item.item_type)) {
        overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/SP+Main+Back.png')`)
      }
      return  `${overlays.join(',')}`;
    }
   
    if (isVests(item.item_type)) {
      overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/MainBackVest.png')`);
      return  `${overlays.join(',')}`;
    }
    if (isLongSleeveShirts(item.item_type)) {
      overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/MainLSBack.png')`);
      return  `${overlays.join(',')}`;
    }
    if (isShortSleeveShirts(item.item_type)) {
      overlays.push(`url('https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/overlay/MainSSBack.png')`);
      return  `${overlays.join(',')}`;
    }
    
    return  `${overlays.join(',')}`;
  }

  return (
    <>
      <div style={{height: vertical ? '50vh' : '600px', position:'relative'}}>
        {vertical ? (
          <>
            <Row>
              <Col>
                <canvas className={styles.container} style={{backgroundImage: `${frontOverlayImages()}`}}></canvas>
              </Col>
            </Row>
            <Row>
              <Col>
                <canvas className={styles.container} style={{backgroundImage: `${backOverlayImages()}`}}></canvas>
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col>
              <canvas className={styles.container} style={{backgroundImage: `${frontOverlayImages()}`}}></canvas>
            </Col>
            <Col>
              <canvas className={styles.container} style={{backgroundImage: `${backOverlayImages()}`}}></canvas>
            </Col>
          </Row>
        )}
      </div>
      {/* <div><pre>{JSON.stringify(JSON.parse(item.design), null, 2) }</pre></div> */}
      {/* <div><pre>{JSON.stringify(Object.values(JSON.parse(item.design)), null, 2) }</pre></div> */}

    </>
  )
}

export default DesignStack